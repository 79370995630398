import React from 'react';
import { Globe, Facebook } from 'lucide-react';

const SourceIcon = ({ source, className = "" }) => {
  const getSourceDetails = (source) => {
    switch (source) {
      case 'default':
      case 'source_2':
        return {
          Icon: Globe,
          tooltip: source === 'default' ? 'Website' : 'Web Campaign',
          colorClass: source === 'default' ? 'text-blue-500' : 'text-green-500'
        };
      case 'source_3':
        return {
          Icon: Facebook,
          tooltip: 'Facebook',
          colorClass: 'text-blue-600'
        };
      default:
        return {
          Icon: Globe,
          tooltip: 'Unknown Source',
          colorClass: 'text-gray-400'
        };
    }
  };

  const { Icon, tooltip, colorClass } = getSourceDetails(source);

  return (
    <div className={`inline-flex items-center ${className}`} title={tooltip}>
      <Icon className={`h-4 w-4 ${colorClass}`} />
    </div>
  );
};

export default SourceIcon;