import React, { useState, useCallback } from 'react';
import { Phone, Calendar, MessageSquare, Clock, Tag as TagIcon } from 'lucide-react';
import LeadDetailsDrawer from './LeadDetailsDrawer';
import moment from 'moment';
import { message, Spin } from 'antd';
import { getLead } from '../apiService';

// Dark theme colors
const darkTheme = {
  backgroundColor: '#1f1f1f',
  cardBg: '#262626',
  textPrimary: '#ffffff',
  textSecondary: '#a0a0a0',
  borderColor: '#333333',
  hoverBg: '#363636',
  statusColors: {
    'Follow Up': { bg: '#153a5b', text: '#63b3ed' },
    'Demo Done': { bg: '#1c4532', text: '#68d391' },
    'Details Shared': { bg: '#154e4e', text: '#4fd1c5' },
    'NP1': { bg: '#44337a', text: '#b794f4' },
    default: { bg: '#533f20', text: '#f6ad55' }
  }
};

// Card styles
const cardStyle = {
  backgroundColor: darkTheme.cardBg,
  borderColor: darkTheme.borderColor,
  color: darkTheme.textPrimary,
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.9), 0 0 8px rgba(255, 255, 255, 0.1)',
  transition: 'transform 0.2s ease, box-shadow 0.3s ease',
};

const cardHoverStyle = {
  transform: 'translateY(-2px)',
  boxShadow: '0 6px 20px rgba(0, 0, 0, 0.4), 0 0 16px rgba(255, 255, 255, 0.2)'
};

// Date formatting helpers [Previous date formatting functions remain the same]
const formatDateToAPI = (date) => {
  return date ? moment(date).format('YYYY-MM-DD') : null;
};

const formatTimeToAPI = (time) => {
  return time ? moment(time, 'hh:mm A').format('HH:mm:ss') : null;
};

const formatDateTimeToAPI = (dateTime) => {
  return dateTime ? moment(dateTime).format('YYYY-MM-DD HH:mm:ss') : null;
};

const formatDateWithConditionalYear = (dateString) => {
  if (!dateString) return null;
  const date = moment(dateString);
  if (!date.isValid()) return null;
  const currentYear = moment().year();
  return date.year() === currentYear ? date.format('D-MMM') : date.format('D-MMM-YYYY');
};

const formatDateTime = (dateTimeStr) => {
  if (!dateTimeStr) return null;
  const date = moment(dateTimeStr);
  if (!date.isValid()) return null;
  return date.format('D-MMM-YYYY hh:mm A');
};

const formatCallDateTimeForDisplay = (dateTime) => {
  return dateTime ? moment(dateTime).format('D-MMM-YYYY hh:mm A') : null;
};

const formatTimeForDisplay = (time) => {
  return time ? moment(time, 'HH:mm:ss').format('hh:mm A') : null;
};

const formatDateForDisplay = (date) => {
  return date ? moment(date).format('D-MMM-YYYY') : null;
};

const formatLeadForDisplay = (lead) => {
  if (!lead) return lead;

  return {
    ...lead,
    // Basic fields
    id: lead.id,
    name: lead.name,
    phone: lead.phone,
    email: lead.email,
    course: lead.course,
    status: lead.status || 'Unconfirmed',
    priority: lead.priority,
    revenue: lead.revenue,
    coordinator_id: lead.coordinator_id,
    coordinator_name: lead.coordinator_name,
    demo_language: lead.demo_language,
    fee_due: lead.fee_due,
    fee_due_date: lead.fee_due_date,

    // Date fields
    booking_date: formatDateForDisplay(lead.booking_date),
    booking_time: formatTimeForDisplay(lead.booking_time),
    call_date: formatCallDateTimeForDisplay(lead.call_date),
    next_call_date: formatCallDateTimeForDisplay(lead.next_call_date),
    date: lead.date ? moment(lead.date).format('YYYY-MM-DD') : null,

    // Handle notes with proper transformation
    notes: (lead.notes || lead.recent_notes || []).map(note => ({
      id: note.id || Math.random().toString(),
      note_content: note.note_content || note.content,
      created_at: note.created_at 
        ? moment(note.created_at).format('YYYY-MM-DD HH:mm:ss')
        : null,
      created_by: note.created_by?.id || note.created_by,
      created_by_name: note.created_by_name || note.created_by?.name || 'Unknown',
      lead_id: note.lead_id || lead.id
    })),

    // Handle status history
    status_history: (lead.status_history || []).map(history => ({
      ...history,
      changed_at: history.changed_at 
        ? moment(history.changed_at).format('YYYY-MM-DD HH:mm:ss')
        : null
    })),

    // Handle assignment history
    assignment_history: (lead.assignment_history || []).map(history => ({
      ...history,
      assigned_at: history.assigned_at 
        ? moment(history.assigned_at).format('YYYY-MM-DD HH:mm:ss')
        : null
    })),

    // Additional metadata
    attachments: lead.attachments || [],
    no_contact_history: lead.no_contact_history || []
  };
};

const timeOptions = [
  '11:00 AM', '04:00 PM', '08:00 PM', '09:00 AM', '10:00 AM', '12:00 PM',
  '02:00 PM', '03:00 PM', '05:00 PM', '05:30 PM', '06:00 PM', '06:30 PM',
  '07:00 PM', '07:30 PM', '08:30 PM', '09:00 PM', '09:30 PM', '10:00 PM'
];

const getLanguageColor = (language) => {
  switch (language) {
    case 'Hindi':
      return '#68d391';
    case 'English':
      return '#fc8181';
    default:
      return '#f6e05e';
  }
};

const LeadCard = ({ 
  lead: initialLead, 
  onLeadUpdate,
  onAddNote,
  onCoordinatorClick,
  refreshData,
  currentUser,
  variant = 'demo',
  onLeadChange,
}) => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [completeLeadData, setCompleteLeadData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [localLead, setLocalLead] = useState(initialLead);

  React.useEffect(() => {
    setLocalLead(initialLead);
  }, [initialLead]);

  const fetchCompleteLeadData = async (leadId) => {
    try {
      setLoading(true);
      const response = await getLead(leadId);
      
      if (response) {
        const formattedLead = formatLeadForDisplay(response);
        setCompleteLeadData(formattedLead);
        setIsDrawerVisible(true);
      } else {
        message.error('Lead details not found');
      }
    } catch (error) {
      console.error('Error fetching lead details:', error);
      message.error('Failed to fetch lead details');
    } finally {
      setLoading(false);
    }
  };

  const handleCardClick = () => {
    fetchCompleteLeadData(localLead.id);
  };

  const handleDrawerClose = () => {
    setIsDrawerVisible(false);
    setCompleteLeadData(null);
  };

  const handleLeadUpdate = async (leadId, field, value) => {
    try {
      let updatedLead = { ...localLead };
      
      switch (field) {
        case 'booking_date':
          updatedLead.booking_date = value ? moment(value).format('D-MMM-YYYY') : null;
          break;
          
        case 'booking_time':
          updatedLead.booking_time = value ? moment(value, 'HH:mm:ss').format('hh:mm A') : null;
          break;
          
        case 'call_date':
          updatedLead.call_date = value ? moment(value).format('D-MMM-YYYY hh:mm A') : null;
          break;
          
        case 'next_call_date':
          updatedLead.next_call_date = value ? moment(value).format('D-MMM-YYYY hh:mm A') : null;
          break;
          
        case 'fee_due_date':
          updatedLead.fee_due_date = value ? moment(value).format('YYYY-MM-DD') : null;
          break;
          
        default:
          updatedLead[field] = value;
      }

      setLocalLead(updatedLead);
      
      if (completeLeadData) {
        setCompleteLeadData(prevData => ({
          ...prevData,
          ...updatedLead
        }));
      }
      
      onLeadChange?.(updatedLead);

      let apiValue = value;
      if (field === 'booking_date') {
        apiValue = formatDateToAPI(value);
      } else if (field === 'booking_time') {
        apiValue = formatTimeToAPI(value);
      } else if (field === 'call_date' || field === 'next_call_date') {
        apiValue = formatDateTimeToAPI(value);
      }

      await onLeadUpdate(leadId, field, apiValue);
      
    } catch (error) {
      console.error('Error updating lead:', error);
      message.error('Failed to update lead');
      
      const response = await getLead(leadId);
      if (response) {
        const formattedLead = formatLeadForDisplay(response);
        setLocalLead(formattedLead);
        if (completeLeadData) {
          setCompleteLeadData(formattedLead);
        }
        onLeadChange?.(formattedLead);
      }
    }
  };

  const handleNoteAdd = async (leadId, noteContent) => {
    try {
      await onAddNote(leadId, noteContent);
      const response = await getLead(leadId);
      if (response) {
        const formattedLead = formatLeadForDisplay(response);
        setCompleteLeadData(formattedLead);
        setLocalLead(prev => ({
          ...prev,
          notes: formattedLead.notes,
          recent_notes: formattedLead.recent_notes
        }));
        onLeadChange?.(formattedLead);
      }
    } catch (error) {
      console.error('Error adding note:', error);
      message.error('Failed to add note');
    }
  };

  const displayDateTime = variant === 'nextCall' ? 
    formatCallDateTimeForDisplay(localLead.next_call_date) : 
    formatDateTime(localLead.booking_time ? 
      `${localLead.booking_date} ${localLead.booking_time}` :
      localLead.booking_date);

  return (
    <>
      <div 
        className="rounded-xl p-4 cursor-pointer relative hover:-translate-y-0.5"
        onClick={handleCardClick}
        style={{ 
          ...cardStyle,
          pointerEvents: loading ? 'none' : 'auto',
        }}
        onMouseEnter={(e) => {
          Object.assign(e.currentTarget.style, cardHoverStyle);
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.transform = '';
          e.currentTarget.style.boxShadow = cardStyle.boxShadow;
        }}
      >
        {loading && (
          <div className="absolute inset-0 flex items-center justify-center rounded-xl" 
               style={{ backgroundColor: 'rgba(38, 38, 38, 0.5)' }}>
            <Spin />
          </div>
        )}

        <div className="flex justify-between items-start mb-3">
          <div>
            <h3 className="font-medium text-lg" style={{ color: darkTheme.textPrimary }}>
              {localLead.name}
            </h3>
            <div className="flex items-center gap-2 mt-1" style={{ color: darkTheme.textSecondary }}>
              <Phone className="h-4 w-4" />
              <span>{localLead.phone}</span>
            </div>
          </div>
          {localLead.status && (
            <span className="px-3 py-1 rounded-full text-sm" style={{
              backgroundColor: darkTheme.statusColors[localLead.status]?.bg || darkTheme.statusColors.default.bg,
              color: darkTheme.statusColors[localLead.status]?.text || darkTheme.statusColors.default.text
            }}>
              {localLead.status}
            </span>
          )}
        </div>

        <div className="space-y-3">
          {localLead.course && (
            <div className="flex items-center gap-2" style={{ color: darkTheme.textSecondary }}>
              <TagIcon className="h-4 w-4" style={{ color: '#7f9cf5' }} />
              <span>{localLead.course}</span>
            </div>
          )}
          
          <div className="flex gap-1.5">
            <div className="relative group">
              <div className="text-xs px-2 py-1 rounded border" style={{
                backgroundColor: '#2d3748',
                borderColor: '#4a5568',
                color: darkTheme.textPrimary
              }}>
                {formatDateWithConditionalYear(localLead.date)}
              </div>
            </div>
          </div>
          
          {displayDateTime && (
            <div className="flex items-center gap-2" style={{ color: darkTheme.textSecondary }}>
              <Clock className="h-4 w-4" style={{ color: '#68d391' }} />
              <span>{displayDateTime}</span>
            </div>
          )}

          {(localLead.notes?.length > 0 || localLead.recent_notes?.length > 0) && (
           <div className="mt-4 pt-3 border-t" style={{ borderColor: darkTheme.borderColor }}>
           <div className="flex items-center gap-2 mb-2" style={{ color: darkTheme.textSecondary }}>
             <MessageSquare className="h-4 w-4" />
             <span className="text-sm font-medium">Recent Notes</span>
           </div>
           <div className="space-y-2">
             {(localLead.notes || localLead.recent_notes)?.slice(0, 2).map((note, idx) => (
               <div key={idx} className="rounded-lg p-3 text-sm" style={{ 
                 backgroundColor: '#2d3748',
                 boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
               }}>
                 <p style={{ color: darkTheme.textPrimary }}>{note.note_content || note.content}</p>
                 <p style={{ color: darkTheme.textSecondary }} className="text-xs mt-1">
                   by {note.created_by_name || note.created_by?.name || 'Unknown'} • {formatDateTime(note.created_at)}
                 </p>
               </div>
             ))}
           </div>
         </div>
       )}
     </div>
   </div>

   {isDrawerVisible && completeLeadData && (
     <LeadDetailsDrawer
       lead={completeLeadData}
       visible={isDrawerVisible}
       onClose={handleDrawerClose}
       onEdit={handleLeadUpdate}
       timeOptions={timeOptions}
       getLanguageColor={getLanguageColor}
       onAddNote={handleNoteAdd}
       onCoordinatorClick={onCoordinatorClick}
       refreshLeads={async () => {
         const response = await getLead(completeLeadData.id);
         if (response) {
           const formattedLead = formatLeadForDisplay(response);
           setCompleteLeadData(formattedLead);
           setLocalLead(formattedLead);
           onLeadChange?.(formattedLead);
         }
       }}
       currentUser={currentUser}
       theme={darkTheme}
     />
   )}
 </>
);
};

export const DemosGrid = ({ 
demos: initialDemos, 
onLeadUpdate,
onAddNote,
onCoordinatorClick,
refreshData,
currentUser
}) => {
const [demos, setDemos] = useState(initialDemos?.map(demo => formatLeadForDisplay(demo)) || []);

React.useEffect(() => {
 const formattedDemos = initialDemos?.map(demo => formatLeadForDisplay(demo)) || [];
 setDemos(formattedDemos);
}, [initialDemos]);

const handleLeadChange = useCallback((updatedLead) => {
 setDemos(prevDemos => 
   prevDemos.map(demo => 
     demo.id === updatedLead.id ? formatLeadForDisplay(updatedLead) : demo
   )
 );
}, []);

if (!demos?.length) {
 return (
   <div className="text-center py-8 rounded-xl" style={{ 
     ...cardStyle,
     color: darkTheme.textSecondary 
   }}>
     <Calendar className="h-8 w-8 mx-auto mb-2" />
     <p>No demos scheduled for today</p>
   </div>
 );
}

return (
 <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
   {demos.map((demo, index) => (
     <LeadCard 
       key={demo.id || index}
       lead={demo}
       onLeadUpdate={onLeadUpdate}
       onAddNote={onAddNote}
       onCoordinatorClick={onCoordinatorClick}
       refreshData={refreshData}
       currentUser={currentUser}
       onLeadChange={handleLeadChange}
     />
   ))}
 </div>
);
};

export const NextCallsGrid = ({ 
nextCallLeads: initialNextCallLeads,
onLeadUpdate,
onAddNote,
onCoordinatorClick,
refreshData,
currentUser
}) => {
const [nextCallLeads, setNextCallLeads] = useState(initialNextCallLeads);

React.useEffect(() => {
 setNextCallLeads(initialNextCallLeads);
}, [initialNextCallLeads]);

const handleLeadChange = useCallback((updatedLead) => {
 setNextCallLeads(prev => {
   if (!prev?.leads) return prev;
   return {
     ...prev,
     leads: prev.leads.map(lead => 
       lead.id === updatedLead.id ? formatLeadForDisplay(updatedLead) : lead
     )
   };
 });
}, []);

if (!nextCallLeads?.leads?.length) {
 return (
   <div className="text-center py-8 rounded-xl" style={{ 
     ...cardStyle,
     color: darkTheme.textSecondary 
   }}>
     <Phone className="h-8 w-8 mx-auto mb-2" />
     <p>No follow-up calls scheduled</p>
   </div>
 );
}

const formattedLeads = nextCallLeads.leads.map(lead => formatLeadForDisplay(lead));

return (
 <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
   {formattedLeads.map((lead, index) => (
     <LeadCard 
       key={lead.id || index}
       lead={lead}
       onLeadUpdate={onLeadUpdate}
       onAddNote={onAddNote}
       onCoordinatorClick={onCoordinatorClick}
       refreshData={refreshData}
       currentUser={currentUser}
       variant="nextCall"
       onLeadChange={handleLeadChange}
     />
   ))}
 </div>
);
};

// Helper function to format a date string based on whether it's in the current year
export const formatLeadDate = formatDateWithConditionalYear;

// Helper function to format a date and time string for display
export const formatLeadDateTime = formatDateTime;

export { formatLeadForDisplay };

export default { DemosGrid, NextCallsGrid };