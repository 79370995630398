import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { 
  Button, 
  Input, 
  Select, 
  DatePicker, 
  TimePicker, 
  message, 
  Tag, 
  Skeleton, 
  Spin, 
  Modal, 
  List, 
  Checkbox, 
  Drawer,
  Card,
  Pagination,
  Typography,
  Badge,
  Tabs,
  Tooltip,
  Collapse,
  Space
} from 'antd';
import { 
  EditOutlined, 
  DeleteOutlined, 
  UserOutlined, 
  ReloadOutlined,
  PhoneOutlined,
  BookOutlined,
  ClockCircleOutlined,
  DollarOutlined,
  CalendarOutlined,
  SearchOutlined,
  FilterOutlined,
  MenuOutlined,
  PlusOutlined,
  MoreOutlined,
  UpOutlined,
  DownOutlined,
  WhatsAppOutlined,
  ArrowLeftOutlined,
  CheckOutlined
} from '@ant-design/icons';
import moment from 'moment-timezone';
import { 
  getLeads, 
  updateLead, 
  addNote, 
  bulkAssignLeads, 
  getAllUsers, 
  createLead, 
  deleteLead, 
  getUserDetailsById 
} from '../apiService';
import RedesignedMobileNotes from './MobileNotesPopover';
import SourceIcon from './SourceIcon';

import {
  formatDateForAPI,
  formatTimeForAPI,
  formatDateTimeForAPI,
  formatDateForDisplay,
  formatTimeForDisplay,
  formatDateTimeForDisplay,
  parseDateFromAPI,
  parseTimeFromAPI,
  parseDateTimeFromAPI,
  isValidDate,
  isValidTime
} from '../utils/dateUtils';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Text, Title } = Typography;
const { TabPane } = Tabs;
const { Panel } = Collapse;

// Mobile Lead Card Component - Slimmer version for better mobile viewing
const MobileLeadCard = ({ 
  lead, 
  onEdit, 
  selected, 
  onSelect, 
  userNames, 
  fetchUserName,
  onAddNote,
  onCoordinatorClick,
  onDemoLanguageEdit,
  timeOptions,
  isAdmin,
  currentUser,
  onCardClick
}) => {
  const statusStyle = getStatusColor(lead.status);
  
  return (
    <Card
      className={`mb-3 shadow-sm hover:shadow transition-shadow relative overflow-hidden ${
        lead.recentlyUpdated ? 'border-l-4 border-l-green-400' : lead.notUpdated ? 'border-l-4 border-l-red-400' : 'border-gray-200'
      }`}
      bodyStyle={{ padding: '12px', position: 'relative' }}
      onClick={() => onCardClick(lead)}
    >
      {/* Header */}
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center">
          <Checkbox 
            checked={selected}
            onChange={(e) => {
              e.stopPropagation();
              onSelect(lead.id, e.target.checked);
            }}
            className="mr-2"
          />
          <Tag 
            color={statusStyle.background} 
            style={{ color: statusStyle.color }}
            className="mr-2"
          >
            {lead.status}
          </Tag>
          <Tag color={lead.demo_language === 'English' ? 'red' : 'green'}>
            {lead.demo_language || 'No Lang'}
          </Tag>
        </div>
        <span className="text-gray-500 text-xs">#{lead.number}</span>
      </div>
      
      {/* Lead Name & Source */}
      <div className="flex items-center mb-2">
        <SourceIcon source={lead.source} className="mr-2" />
        <Text strong className="text-base truncate">{lead.name}</Text>
      </div>
      
      {/* Essential Info */}
      <div className="grid grid-cols-2 gap-2 mb-2 text-sm">
        <div className="flex items-center">
          <PhoneOutlined className="mr-1 text-gray-500" />
          <span className="truncate">{lead.phone}</span>
        </div>
        <div className="flex items-center">
          <BookOutlined className="mr-1 text-gray-500" />
          <span className="truncate">{lead.course || 'No course'}</span>
        </div>
      </div>
      
      {/* Booking & Call Info */}
      <div className="bg-gray-50 p-2 rounded mb-2 text-sm">
        <div className="flex flex-wrap gap-y-2">
          {/* Booking Date & Time */}
          {lead.booking_date && (
            <>
              <div className="flex items-center w-1/2">
                <CalendarOutlined className="mr-1 text-gray-500" />
                <span className="truncate">{lead.booking_date}</span>
              </div>
              <div className="flex items-center w-1/2">
                <ClockCircleOutlined className="mr-1 text-gray-500" />
                <span className="truncate">{lead.booking_time || 'No time'}</span>
              </div>
            </>
          )}
          
          {/* Call Date */}
          {lead.call_date && (
            <div className="flex items-center w-full">
              <PhoneOutlined className="mr-1 text-blue-500" />
              <span className="truncate text-blue-600">Last: {lead.call_date}</span>
            </div>
          )}
          
          {/* Next Call Date */}
          {lead.next_call_date && (
            <div className="flex items-center w-full">
              <CalendarOutlined className="mr-1 text-green-500" />
              <span className="truncate text-green-600">Next: {lead.next_call_date}</span>
            </div>
          )}
        </div>
      </div>
      
      {/* Revenue & Coordinator */}
      <div className="flex justify-between items-center mt-2 text-sm">
        <div className="flex items-center">
          <DollarOutlined className="mr-1 text-gray-500" />
          <Text strong className="text-gray-800">{lead.revenue ? `₹${lead.revenue}` : '₹0'}</Text>
        </div>
        <div className="flex items-center">
          <UserOutlined className="mr-1 text-gray-500" />
          <Text className="text-gray-600 truncate">
            {lead.coordinator_name || 'Unassigned'}
          </Text>
        </div>
      </div>
      
      {/* Latest Note Indicator */}
      {lead.notes && lead.notes.length > 0 && (
        <div className="mt-2 pt-2 border-t border-gray-100 text-xs text-gray-500">
          Last note: {lead.notes[0].note_content.substring(0, 40)}...
        </div>
      )}
    </Card>
  );
};

// Mobile Lead Detail View Component
// Improved MobileLeadDetail component
// Update this section in MobileLeadsView.js

// Updated MobileLeadDetail component
// Update this section in MobileLeadsView.js

// Updated MobileLeadDetail component
// Update this section in MobileLeadsView.js

const MobileLeadDetail = ({
    lead,
    onEdit,
    onBack,
    userNames,
    fetchUserName,
    onAddNote,
    onCoordinatorClick,
    onDemoLanguageEdit,
    timeOptions,
    isAdmin
  }) => {
    const statusStyle = getStatusColor(lead.status);
    const [activeTab, setActiveTab] = useState('1');
    
    // Effect to lock body scroll when detail view is open
    useEffect(() => {
      document.body.classList.add('detail-view-active');
      
      return () => {
        document.body.classList.remove('detail-view-active');
      };
    }, []);
    
    // Return null early if no lead
    if (!lead) return null;
    
    return (
      <div className="bg-white min-h-screen h-full flex flex-col">
        {/* Header - Make sticky */}
        <div className="sticky top-0 z-10 bg-white shadow-sm px-4 py-3 flex items-center">
          <Button 
            type="text" 
            icon={<ArrowLeftOutlined />} 
            onClick={onBack}
            className="mr-3"
          />
          <div>
            <div className="flex items-center gap-2">
              <SourceIcon source={lead.source} />
              <Text strong className="text-lg">{lead.name}</Text>
            </div>
            <div className="flex items-center gap-2 mt-1">
              <Text className="text-gray-500 text-sm">
                Lead #{lead.number} • {lead.phone}
              </Text>
            </div>
          </div>
        </div>
        
        {/* Status and Demo Language */}
        <div className="px-4 py-3 flex justify-between items-center bg-gray-50">
          <Select
            value={lead.status}
            onChange={(value) => onEdit(lead.id, 'status', value)}
            style={{ width: 150 }}
            bordered={false}
          >
            {[
              'Unconfirmed', 'Demo Booked', 'Demo Done', 'Demo Missed',
              'Follow Up', 'Details Shared', 'Offer Given', 'For Offer',
              'NP1', 'NP2', 'NP3', 'Closed', 'Future Follow', 'Admission'
            ].map(status => (
              <Option key={status} value={status}>
                <span className={getStatusColor(status).className}>
                  {status}
                </span>
              </Option>
            ))}
          </Select>
          
          <Tag
            color={lead.demo_language === 'English' ? 'red' : 'green'}
            style={{ cursor: 'pointer' }}
            onClick={() => onDemoLanguageEdit(lead)}
          >
            {lead.demo_language || 'No Language'}
          </Tag>
        </div>
        
        {/* Main Content Tabs - Simplified to just Details and Notes */}
        <div className="flex-grow flex flex-col">
          <Tabs 
            activeKey={activeTab} 
            onChange={setActiveTab}
            centered
            className="px-4 pt-2"
          >
            <TabPane tab="Details" key="1">
              <div className="p-4 pb-20 overflow-y-auto h-full" style={{ maxHeight: 'calc(100vh - 210px)' }}>
                {/* Core Details Section */}
                <Collapse 
                  defaultActiveKey={['1']} 
                  bordered={false}
                  className="bg-white mb-4"
                >
                  <Panel header="Core Details" key="1">
                    <div className="grid grid-cols-2 gap-4 mb-4">
                      <div>
                        <div className="text-gray-500 text-sm mb-1">Name</div>
                        <Input
                          value={lead.name}
                          onChange={(e) => onEdit(lead.id, 'name', e.target.value)}
                          className="w-full"
                        />
                      </div>
                      <div>
                        <div className="text-gray-500 text-sm mb-1">Phone</div>
                        <Input
                          value={lead.phone}
                          onChange={(e) => onEdit(lead.id, 'phone', e.target.value)}
                          className="w-full"
                        />
                      </div>
                      <div>
                        <div className="text-gray-500 text-sm mb-1">Course</div>
                        <Input
                          value={lead.course}
                          onChange={(e) => onEdit(lead.id, 'course', e.target.value)}
                          className="w-full"
                        />
                      </div>
                      <div>
                        <div className="text-gray-500 text-sm mb-1">Revenue</div>
                        <Input
                          value={lead.revenue}
                          onChange={(e) => onEdit(lead.id, 'revenue', e.target.value)}
                          className="w-full"
                          prefix="₹"
                        />
                      </div>
                    </div>
                  </Panel>
                </Collapse>
                
                {/* Booking Details Section */}
                <Collapse 
                  defaultActiveKey={['1']} 
                  bordered={false}
                  className="bg-white mb-4"
                >
                  <Panel header="Booking Details" key="1">
                    <div className="grid grid-cols-2 gap-4 mb-4">
                      <div>
                        <div className="text-gray-500 text-sm mb-1">Booking Date</div>
                        <DatePicker
                          value={lead.booking_date ? moment(lead.booking_date, 'DD-MMM-YYYY') : null}
                          format="DD-MMM-YYYY"
                          onChange={(date) => onEdit(lead.id, 'booking_date', date ? date.format('DD-MMM-YYYY') : null)}
                          placeholder="Select date"
                          className="w-full"
                        />
                      </div>
                      <div>
                        <div className="text-gray-500 text-sm mb-1">Booking Time</div>
                        <Select
                          value={lead.booking_time || undefined}
                          onChange={(value) => onEdit(lead.id, 'booking_time', value)}
                          className="w-full"
                          placeholder="Select time"
                        >
                          {timeOptions.map(time => (
                            <Option key={time} value={time}>{time}</Option>
                          ))}
                        </Select>
                      </div>
                      <div>
                        <div className="text-gray-500 text-sm mb-1">Priority</div>
                        <Select
                          value={lead.priority || undefined}
                          onChange={(value) => onEdit(lead.id, 'priority', value)}
                          className="w-full"
                          placeholder="Set priority"
                        >
                          <Option value="Low">Low</Option>
                          <Option value="Medium">Medium</Option>
                          <Option value="High">High</Option>
                        </Select>
                      </div>
                      <div>
                        <div className="text-gray-500 text-sm mb-1">Coordinator</div>
                        <Button 
                          type="text"
                          onClick={() => onCoordinatorClick(lead)}
                          className="p-0 h-auto text-blue-600 hover:text-blue-800"
                        >
                          {lead.coordinator_name || 'Unassigned'}
                        </Button>
                      </div>
                    </div>
                  </Panel>
                </Collapse>
                
                {/* Call Scheduling Section */}
                <Collapse 
                  defaultActiveKey={['1']} 
                  bordered={false}
                  className="bg-white mb-4"
                >
                  <Panel header="Call Schedule" key="1">
                    <div className="grid grid-cols-1 gap-4 mb-4">
                      <div>
                        <div className="text-gray-500 text-sm mb-1">Call Date</div>
                        <DatePicker
                          showTime={{ format: 'HH:mm', use12Hours: true }}
                          format="D-MMM-YYYY hh:mm A"
                          value={lead.call_date ? moment(lead.call_date, 'D-MMM-YYYY hh:mm A') : null}
                          onChange={(date, dateString) => onEdit(lead.id, 'call_date', dateString)}
                          placeholder="Select date & time"
                          className="w-full"
                        />
                      </div>
                      <div>
                        <div className="text-gray-500 text-sm mb-1">Next Call Date</div>
                        <DatePicker
                          showTime={{ format: 'HH:mm', use12Hours: true }}
                          format="D-MMM-YYYY hh:mm A"
                          value={lead.next_call_date ? moment(lead.next_call_date, 'D-MMM-YYYY hh:mm A') : null}
                          onChange={(date, dateString) => onEdit(lead.id, 'next_call_date', dateString)}
                          placeholder="Select date & time"
                          className="w-full"
                        />
                      </div>
                    </div>
                  </Panel>
                </Collapse>
                
                {isAdmin && (
                  <Collapse 
                    bordered={false}
                    className="bg-white mb-4"
                  >
                    <Panel header="Assignment History" key="1">
                      <List
                        className="bg-white rounded shadow-sm"
                        itemLayout="horizontal"
                        dataSource={lead.assignment_history || []}
                        renderItem={(item, index) => (
                          <List.Item className="border-b last:border-0">
                            <div className="w-full">
                              <div className="text-sm text-gray-500">
                                {index === 0 ? 'Current: ' : index === 1 ? 'Last: ' : `Previous (${lead.assignment_history.length - index}): `}
                              </div>
                              <div>
                                {item.assigned_from 
                                  ? `${userNames[item.assigned_from] || 'Loading...'} → `
                                  : 'New Assignment: '}
                                {userNames[item.assigned_to] || 'Loading...'}
                              </div>
                              <div className="text-xs text-gray-400">
                                {moment(item.assigned_at).format('D-MMM-YYYY HH:mm')}
                              </div>
                            </div>
                          </List.Item>
                        )}
                      />
                    </Panel>
                  </Collapse>
                )}
              </div>
            </TabPane>
            
            <TabPane tab="Notes" key="2">
              <RedesignedMobileNotes
                leadId={lead.id}
                phone={lead.phone}
                addNote={onAddNote}
                notes={lead.notes}
                statusHistory={lead.status_history}
                refreshNotes={() => {}}
                leadName={lead.name}
                coordinatorName={lead.coordinator_name}
                isMobile={true}
              />
            </TabPane>
          </Tabs>
        </div>
      </div>
    );
  };

// Mobile Filters Drawer Component
const MobileFiltersDrawer = ({
  visible,
  onClose,
  filters,
  onFilterChange,
  onClear,
  onApply,
  users,
  isAdmin,
  timeOptions
}) => {
  return (
    <Drawer
      title="Filters"
      placement="right"
      onClose={onClose}
      open={visible}
      width="85%"
      footer={
        <div className="flex justify-between">
          <Button onClick={onClear}>Clear All</Button>
          <Button type="primary" onClick={() => { onApply(); onClose(); }}>
            Apply Filters
          </Button>
        </div>
      }
    >
      <div className="space-y-4">
        <div>
          <Text strong className="block mb-2">Search</Text>
          <Input
            placeholder="Search leads..."
            value={filters.search}
            onChange={(e) => onFilterChange('search', e.target.value)}
            prefix={<SearchOutlined />}
          />
        </div>
      
        <div>
          <Text strong className="block mb-2">Status</Text>
          <Select
            mode="multiple"
            placeholder="Select statuses"
            value={filters.status}
            onChange={(value) => onFilterChange('status', value)}
            style={{ width: '100%' }}
            maxTagCount={2}
          >
            {['Unconfirmed', 'Demo Booked', 'Demo Done', 'Demo Missed', 
              'Follow Up', 'Details Shared', 'Offer Given', 'For Offer', 
              'NP1', 'NP2', 'NP3', 'Closed', 'Future Follow', 'Admission'
            ].map(status => (
              <Option key={status} value={status}>
                <span className="px-2 py-1 rounded" style={getStatusColor(status)}>
                  {status}
                </span>
              </Option>
            ))}
          </Select>
        </div>
      
        <div>
          <Text strong className="block mb-2">Date Range</Text>
          <RangePicker
            style={{ width: '100%' }}
            format="D-MMM-YYYY"
            value={[
              filters.date_from ? moment(filters.date_from) : null,
              filters.date_to ? moment(filters.date_to) : null
            ]}
            onChange={(dates) => onFilterChange('date_range', dates)}
          />
        </div>
      
        {isAdmin && (
          <div>
            <Text strong className="block mb-2">Coordinator</Text>
            <Select
              style={{ width: '100%' }}
              placeholder="Select coordinator"
              value={filters.coordinator_id}
              onChange={(value) => onFilterChange('coordinator_id', value)}
            >
              <Option value="all">All</Option>
              <Option value="unassigned">Unassigned</Option>
              {users.map(user => (
                <Option key={user.id} value={user.id}>
                  {user.display_name || user.username}
                </Option>
              ))}
            </Select>
          </div>
        )}
      
        <div>
          <Text strong className="block mb-2">Priority</Text>
          <Select
            style={{ width: '100%' }}
            placeholder="Select priority"
            value={filters.priority}
            onChange={(value) => onFilterChange('priority', value)}
          >
            <Option value="">All</Option>
            <Option value="Low">Low</Option>
            <Option value="Medium">Medium</Option>
            <Option value="High">High</Option>
          </Select>
        </div>
      
        <div>
          <Text strong className="block mb-2">Demo Time</Text>
          <Select
            style={{ width: '100%' }}
            placeholder="Select time"
            value={filters.booking_time}
            onChange={(value) => onFilterChange('booking_time', value)}
          >
            <Option value="">All</Option>
            {timeOptions.map(time => (
              <Option key={time} value={time}>{time}</Option>
            ))}
          </Select>
        </div>
      </div>
    </Drawer>
  );
};

// Bulk Actions Sheet Component
const BulkActionsSheet = ({
  visible,
  onClose,
  selectedCount,
  users,
  bulkAssignUser,
  onBulkAssignChange,
  onBulkAssign,
  onBulkDelete
}) => {
  return (
    <Drawer
      title={`${selectedCount} Leads Selected`}
      placement="bottom"
      onClose={onClose}
      open={visible}
      height={300}
    >
      <div className="space-y-4">
        <div>
          <Text strong className="block mb-2">Assign to Coordinator</Text>
          <Select
            placeholder="Select coordinator"
            style={{ width: '100%' }}
            value={bulkAssignUser}
            onChange={onBulkAssignChange}
          >
            {users.map(user => (
              <Option key={user.id} value={user.id}>
                {user.display_name || user.username}
              </Option>
            ))}
          </Select>
        </div>
        
        <div className="flex gap-2">
          <Button 
            type="primary" 
            block 
            onClick={() => {
              onBulkAssign();
              onClose();
            }}
            disabled={!bulkAssignUser}
          >
            Assign Selected
          </Button>
        </div>
        
        <div className="pt-4 border-t">
          <Button 
            type="primary" 
            danger 
            block
            onClick={() => {
              onBulkDelete();
              onClose();
            }}
            icon={<DeleteOutlined />}
          >
            Delete Selected
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

// Utility Function - Status Colors
const getStatusColor = (status) => {
  const statusStyles = {
    'Unconfirmed': { color: 'black', background: '#ffcccb', className: 'text-black bg-[#ffcccb]' },
    'Demo Booked': { color: 'black', background: '#90EE90', className: 'text-black bg-[#90EE90]' },
    'Demo Done': { color: 'white', background: '#006400', className: 'text-white bg-[#006400]' },
    'Demo Missed': { color: 'white', background: '#FFA500', className: 'text-white bg-[#FFA500]' },
    'Follow Up': { color: 'black', background: '#FFFF00', className: 'text-black bg-[#FFFF00]' },
    'Details Shared': { color: 'black', background: '#ADD8E6', className: 'text-black bg-[#ADD8E6]' },
    'Offer Given': { color: 'black', background: '#FFC0CB', className: 'text-black bg-[#FFC0CB]' },
    'For Offer': { color: 'white', background: '#8A2BE2', className: 'text-white bg-[#8A2BE2]' },
    'NP1': { color: 'white', background: '#00008B', className: 'text-white bg-[#00008B]' },
    'NP2': { color: 'white', background: '#00008B', className: 'text-white bg-[#00008B]' },
    'NP3': { color: 'white', background: '#00008B', className: 'text-white bg-[#00008B]' },
    'Closed': { color: 'white', background: '#8B0000', className: 'text-white bg-[#8B0000]' },
    'Future Follow': { color: 'white', background: '#008B8B', className: 'text-white bg-[#008B8B]' },
    'Admission': { color: 'white', background: '#006400', className: 'text-white bg-[#006400]' }
  };
  return statusStyles[status] || { color: 'black', background: 'white', className: 'text-black bg-white' };
};

// Main Component
const MobileLeadsView = ({ currentUser }) => {
  // States
  const [leads, setLeads] = useState([]);
  const [closedAndAdmissionLeads, setClosedAndAdmissionLeads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [users, setUsers] = useState([]);
  const [filters, setFilters] = useState({
    page: 1,
    per_page: 10, // Reduced for mobile
    search: '',
    status: '',
    date_from: null,
    date_to: null,
    coordinator_id: currentUser && currentUser.role === 'crm_executive' ? currentUser.user_id : '',
    booking_date: null,
    booking_time: null,
    priority: '',
  });
  const [totalPages, setTotalPages] = useState(1);
  const [leadsLoaded, setLeadsLoaded] = useState(false);
  const [usersLoaded, setUsersLoaded] = useState(false);
  const [userNames, setUserNames] = useState({});
  const [filtersDrawerVisible, setFiltersDrawerVisible] = useState(false);
  const [bulkActionDrawerVisible, setBulkActionDrawerVisible] = useState(false);
  const [coordinatorModalVisible, setCoordinatorModalVisible] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [bulkAssignUser, setBulkAssignUser] = useState('');
  const [detailViewVisible, setDetailViewVisible] = useState(false);
  const [currentLead, setCurrentLead] = useState(null);
  const [demoLanguageModalVisible, setDemoLanguageModalVisible] = useState(false);
  const [editingLead, setEditingLead] = useState(null);
  
  const isAdmin = currentUser.role === 'administrator';
  const isCrmExecutive = currentUser.role === 'crm_executive';

  const timeOptions = [
    '11:00 AM', '04:00 PM', '08:00 PM', '09:00 AM', '10:00 AM', '12:00 PM',
    '02:00 PM', '03:00 PM', '05:00 PM', '05:30 PM', '06:00 PM', '06:30 PM',
    '07:00 PM', '07:30 PM', '08:30 PM', '09:00 PM', '09:30 PM', '10:00 PM'
  ];

  // Fetch user name function
  const fetchUserName = useCallback(async (userId) => {
    if (!userNames[userId]) {
      try {
        const userDetails = await getUserDetailsById(userId);
        setUserNames(prev => ({
          ...prev,
          [userId]: userDetails.display_name
        }));
      } catch (error) {
        console.error(`Error fetching user details for ID ${userId}:`, error);
        setUserNames(prev => ({
          ...prev,
          [userId]: 'Unknown'
        }));
      }
    }
  }, [userNames]);

  // Fetch leads function
  const fetchLeads = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const formattedFilters = {
        page: filters.page,
        per_page: filters.per_page,
        search: filters.search || undefined,
        status: filters.status || undefined,
        date_from: filters.date_from ? formatDateForAPI(filters.date_from) : undefined,
        date_to: filters.date_to ? formatDateForAPI(filters.date_to) : undefined,
        booking_date: filters.booking_date ? formatDateForAPI(filters.booking_date) : undefined,
        booking_time: filters.booking_time || undefined,
        priority: filters.priority || undefined,
        coordinator_id: currentUser.role === 'crm_executive' ? currentUser.user_id : filters.coordinator_id,
      };

      const cleanFilters = Object.fromEntries(
        Object.entries(formattedFilters).filter(([_, v]) => v !== undefined && v !== '')
      );

      const response = await getLeads(cleanFilters);
      
      const leadsWithDetails = response.leads.map((lead, index) => ({
        ...lead,
        number: index + 1 + (filters.page - 1) * filters.per_page,
        booking_date: lead.booking_date ? formatDateForDisplay(lead.booking_date) : null,
        booking_time: lead.booking_time ? formatTimeForDisplay(lead.booking_time) : null,
        call_date: lead.call_date ? formatCallDateTimeForDisplay(lead.call_date) : null,
        next_call_date: lead.next_call_date ? formatCallDateTimeForDisplay(lead.next_call_date) : null,
        recentlyUpdated: isRecentlyUpdated(lead),
        notUpdated: isNotUpdated(lead),
        assignment_history: lead.assignment_history || [],
      }));

      if (isCrmExecutive) {
        const filteredLeads = leadsWithDetails.filter(lead => 
          lead.status !== 'Closed' && lead.status !== 'Admission'
        );
        const closedAndAdmissionLeads = leadsWithDetails.filter(lead => 
          lead.status === 'Closed' || lead.status === 'Admission'
        );
        setLeads(filteredLeads);
        setClosedAndAdmissionLeads(closedAndAdmissionLeads);
      } else {
        setLeads(leadsWithDetails);
      }

      setTotalPages(response.pages);
      setLeadsLoaded(true);
    } catch (error) {
      console.error('Error fetching leads:', error);
      setError('Failed to load leads. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [filters, currentUser.role, currentUser.user_id, isCrmExecutive]);

  // Fetch users function
  const fetchUsers = useCallback(async () => {
    try {
      const response = await getAllUsers();
      if (response && Array.isArray(response.users)) {
        setUsers(response.users);
        setUsersLoaded(true);
      } else {
        throw new Error('Unexpected user data format');
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      setUsersLoaded(true);
    }
  }, []);

  // Initial data loading
  useEffect(() => {
    fetchLeads();
    fetchUsers();
  }, [fetchLeads, fetchUsers]);

  // Handle filter change
  const handleFilterChange = (name, value) => {
    setFilters(prev => {
      const newFilters = { ...prev, [name]: value, page: 1 };
      
      if (name === 'coordinator_id') {
        if (currentUser.role === 'crm_executive') {
          newFilters.coordinator_id = currentUser.user_id;
        } else {
          if (value === 'unassigned') {
            newFilters.coordinator_id = 'null';
          } else if (value === 'all') {
            newFilters.coordinator_id = '';
          }
        }
      }
      
      if (name === 'date_range') {
        if (value && value.length === 2) {
          newFilters.date_from = value[0].format('YYYY-MM-DD');
          newFilters.date_to = value[1].format('YYYY-MM-DD');
        } else {
          delete newFilters.date_from;
          delete newFilters.date_to;
        }
      }
      
      return newFilters;
    });
  };

  // Handle cell edit
  const handleCellEdit = async (key, dataIndex, value) => {
    try {
      let updatedValue = value;

      switch (dataIndex) {
        case 'booking_date':
          updatedValue = value ? moment(value, 'DD-MMM-YYYY').format('YYYY-MM-DD') : null;
          break;
        case 'booking_time':
          updatedValue = value ? moment(value, 'hh:mm A').format('HH:mm:00') : null;
          break;
        case 'call_date':
        case 'next_call_date':
          updatedValue = value ? parseCallDateTimeForAPI(value) : null;
          break;
        default:
          break;
      }

      const payload = {
        [dataIndex]: updatedValue,
      };

      await updateLead(key, payload);

      // Update UI
      setLeads(prevLeads =>
        prevLeads.map(lead =>
          lead.id === key
            ? {
                ...lead,
                [dataIndex]: (() => {
                  switch (dataIndex) {
                    case 'booking_date':
                      return updatedValue ? moment(updatedValue).format('D-MMM-YYYY') : null;
                    case 'booking_time':
                      return updatedValue ? moment(updatedValue, 'HH:mm:ss').format('hh:mm A') : null;
                    case 'call_date':
                    case 'next_call_date':
                      return updatedValue ? formatCallDateTimeForDisplay(updatedValue) : null;
                    default:
                      return updatedValue;
                  }
                })(),
              }
            : lead
        )
      );

      // Update current lead if in detail view
      if (currentLead && currentLead.id === key) {
        setCurrentLead(prevLead => ({
          ...prevLead,
          [dataIndex]: (() => {
            switch (dataIndex) {
              case 'booking_date':
                return updatedValue ? moment(updatedValue).format('D-MMM-YYYY') : null;
              case 'booking_time':
                return updatedValue ? moment(updatedValue, 'HH:mm:ss').format('hh:mm A') : null;
              case 'call_date':
              case 'next_call_date':
                return updatedValue ? formatCallDateTimeForDisplay(updatedValue) : null;
              default:
                return updatedValue;
            }
          })(),
        }));
      }

      message.success('Lead updated successfully');
    } catch (error) {
      console.error('Error updating lead:', error);
      message.error('Failed to update lead: ' + (error.response?.data?.message || error.message));
    }
  };

  // Handle add note
  const handleAddNote = async (leadId, noteContent) => {
    try {
      await addNote(leadId, noteContent);
      message.success('Note added successfully');
      
      // Update leads list
      setLeads(prevLeads => prevLeads.map(lead => 
        lead.id === leadId 
          ? { 
              ...lead, 
              notes: [{ note_content: noteContent, created_at: new Date().toISOString() }, ...lead.notes],
              recentlyUpdated: true, 
              notUpdated: false 
            }
          : lead
      ));

      // Update current lead if in detail view
      if (currentLead && currentLead.id === leadId) {
        setCurrentLead(prevLead => ({
          ...prevLead,
          notes: [{ note_content: noteContent, created_at: new Date().toISOString() }, ...prevLead.notes],
          recentlyUpdated: true, 
          notUpdated: false 
        }));
      }
    } catch (error) {
      console.error('Error adding note:', error);
      message.error('Failed to add note');
    }
  };

  // Handle bulk assign
  const handleBulkAssign = async () => {
    if (selectedLeads.length === 0 || !bulkAssignUser) {
      return;
    }

    try {
      const data = { 
        lead_ids: selectedLeads, 
        executive_id: bulkAssignUser
      };
      await bulkAssignLeads(data);
      message.success('Leads assigned successfully');
      fetchLeads();
      setSelectedLeads([]);
      setBulkAssignUser('');
      setBulkActionDrawerVisible(false);
    } catch (error) {
      console.error('Error bulk assigning leads:', error);
      message.error('Failed to assign leads: ' + (error.response?.data?.message || error.message));
    }
  };

  // Handle bulk delete
  const handleBulkDelete = async () => {
    try {
      // Implement bulk delete logic here
      message.success('Leads deleted successfully');
      fetchLeads();
      setSelectedLeads([]);
      setBulkActionDrawerVisible(false);
    } catch (error) {
      console.error('Error deleting leads:', error);
      message.error('Failed to delete leads');
    }
  };

  // Handle coordinator assign
  const handleCoordinatorAssign = async (coordinatorId) => {
    try {
      const leadToUpdate = leads.find(lead => lead.id === selectedLead.id);
      if (!leadToUpdate) {
        throw new Error('Lead not found');
      }

      const payload = {
        coordinator_id: coordinatorId
      };

      await updateLead(selectedLead.id, payload);
      message.success('Coordinator assigned successfully');
      setCoordinatorModalVisible(false);
      
      // Refresh leads
      fetchLeads();
    } catch (error) {
      console.error('Error assigning coordinator:', error);
      message.error('Failed to assign coordinator');
    }
  };

  // Handle demo language edit
  const handleDemoLanguageEdit = (lead) => {
    setEditingLead(lead);
    setDemoLanguageModalVisible(true);
  };

  const handleDemoLanguageChange = async (value) => {
    try {
      await handleCellEdit(editingLead.id, 'demo_language', value);
      setDemoLanguageModalVisible(false);
      setEditingLead(null);
    } catch (error) {
      console.error('Error updating demo language:', error);
      message.error('Failed to update demo language');
    }
  };

  // Handle lead card click
  const handleLeadCardClick = (lead) => {
    setCurrentLead(lead);
    setDetailViewVisible(true);
  };

  // Utility Functions
  const formatCallDateTimeForDisplay = (dateTime) => {
    if (!dateTime) return 'NA';
    return moment(dateTime, 'YYYY-MM-DD HH:mm:ss').format('D-MMM-YYYY hh:mm A');
  };

  const parseCallDateTimeForAPI = (dateTime) => {
    if (!dateTime) return null;
    return moment(dateTime, 'D-MMM-YYYY hh:mm A').format('YYYY-MM-DD HH:mm:00');
  };

  const isRecentlyUpdated = (lead) => {
    const lastNote = lead.notes[0];
    const lastStatusChange = lead.status_history[0];
    const lastUpdateTime = Math.max(
      lastNote ? moment(lastNote.created_at).valueOf() : 0,
      lastStatusChange ? moment(lastStatusChange.changed_at).valueOf() : 0
    );
    const now = moment().valueOf();
    const hoursSinceLastUpdate = (now - lastUpdateTime) / (1000 * 60 * 60);
    return hoursSinceLastUpdate <= 3;
  };

  const isNotUpdated = (lead) => {
    const lastNote = lead.notes[0];
    const lastStatusChange = lead.status_history[0];
    const lastUpdateTime = Math.max(
      lastNote ? moment(lastNote.created_at).valueOf() : 0,
      lastStatusChange ? moment(lastStatusChange.changed_at).valueOf() : 0
    );
    const now = moment().valueOf();
    const hoursSinceLastUpdate = (now - lastUpdateTime) / (1000 * 60 * 60);
    return hoursSinceLastUpdate > 72;
  };

  // Render
  return (
    <div className="bg-gray-50 min-h-screen pb-16">
      {/* Fixed Header */}
      <div className="fixed top-0 left-0 right-0 z-20 bg-white shadow-sm">
        <div className="flex justify-between items-center px-4 py-3">
          <h1 className="text-xl font-semibold">Leads</h1>
          <div className="flex items-center space-x-2">
            <Badge count={selectedLeads.length} showZero={false}>
              <Button 
                icon={<MenuOutlined />} 
                shape="circle"
                onClick={() => selectedLeads.length > 0 ? setBulkActionDrawerVisible(true) : null}
                disabled={selectedLeads.length === 0}
              />
            </Badge>
            <Button 
              icon={<FilterOutlined />}
              shape="circle" 
              onClick={() => setFiltersDrawerVisible(true)}
            />
            <Button 
              icon={<ReloadOutlined />}
              shape="circle" 
              onClick={fetchLeads}
            />
          </div>
        </div>
        
        {/* Search Bar */}
        <div className="px-4 pb-2">
          <Input
            placeholder="Search leads..."
            prefix={<SearchOutlined className="text-gray-400" />}
            value={filters.search}
            onChange={(e) => handleFilterChange('search', e.target.value)}
            onPressEnter={fetchLeads}
            allowClear
          />
        </div>
        
        {/* Active Filters Display */}
        {(filters.status || filters.priority || filters.booking_time) && (
          <div className="px-4 py-2 flex flex-wrap gap-2 border-t border-gray-100">
            {filters.status && (
              <Tag closable onClose={() => handleFilterChange('status', '')}>
                Status: {filters.status}
              </Tag>
            )}
            {filters.priority && (
              <Tag closable onClose={() => handleFilterChange('priority', '')}>
                Priority: {filters.priority}
              </Tag>
            )}
            {filters.booking_time && (
              <Tag closable onClose={() => handleFilterChange('booking_time', '')}>
                Time: {filters.booking_time}
              </Tag>
            )}
          </div>
        )}
      </div>
      
      {/* Content with top padding for header */}
      <div className="pt-28 px-4">
        {/* Loading or Error States */}
        {loading && leads.length === 0 ? (
          <div className="flex justify-center my-8">
            <Spin size="large" />
          </div>
        ) : error ? (
          <div className="bg-red-50 border border-red-300 text-red-700 px-4 py-3 rounded mb-4">
            <strong className="font-medium">Error:</strong>
            <span className="block sm:inline ml-1">{error}</span>
          </div>
        ) : leads.length === 0 ? (
          <div className="text-center py-12">
            <div className="text-gray-400 text-lg">No leads found</div>
            <div className="text-gray-500 text-sm mt-2">Try adjusting your filters or create a new lead</div>
            <Button 
              type="primary" 
              icon={<PlusOutlined />}
              className="mt-4"
            >
              Create Lead
            </Button>
          </div>
        ) : (
          <>
            {/* Lead Cards */}
            <div className="space-y-3">
              {leads.map(lead => (
                <MobileLeadCard
                  key={lead.id}
                  lead={lead}
                  onEdit={handleCellEdit}
                  selected={selectedLeads.includes(lead.id)}
                  onSelect={(id, checked) => {
                    if (checked) {
                      setSelectedLeads(prev => [...prev, id]);
                    } else {
                      setSelectedLeads(prev => prev.filter(leadId => leadId !== id));
                    }
                  }}
                  userNames={userNames}
                  fetchUserName={fetchUserName}
                  onAddNote={handleAddNote}
                  onCoordinatorClick={(lead) => {
                    setSelectedLead(lead);
                    setCoordinatorModalVisible(true);
                  }}
                  onDemoLanguageEdit={handleDemoLanguageEdit}
                  timeOptions={timeOptions}
                  isAdmin={isAdmin}
                  currentUser={currentUser}
                  onCardClick={handleLeadCardClick}
                />
              ))}
            </div>
            
            {/* Pagination */}
            <div className="flex justify-center my-6">
              <Pagination
                current={filters.page}
                total={totalPages * filters.per_page}
                pageSize={filters.per_page}
                onChange={(page) => setFilters(prev => ({ ...prev, page }))}
                showSizeChanger={false}
                simple
              />
            </div>
          </>
        )}
      </div>
      
      {/* Fixed Action Button */}
      <div className="fixed bottom-4 right-4">
        <Button 
          type="primary" 
          shape="circle" 
          icon={<PlusOutlined />} 
          size="large"
          style={{ width: 56, height: 56, fontSize: 24 }}
        />
      </div>
      
      {/* Modals and Drawers */}
      {/* 1. Filters Drawer */}
      <MobileFiltersDrawer
        visible={filtersDrawerVisible}
        onClose={() => setFiltersDrawerVisible(false)}
        filters={filters}
        onFilterChange={handleFilterChange}
        onClear={() => {
          setFilters({
            page: 1,
            per_page: 10,
            search: '',
            status: '',
            date_from: null,
            date_to: null,
            coordinator_id: currentUser.role === 'crm_executive' ? currentUser.user_id : '',
            booking_date: null,
            booking_time: null,
            priority: '',
          });
        }}
        onApply={fetchLeads}
        users={users}
        isAdmin={isAdmin}
        timeOptions={timeOptions}
      />
      
      {/* 2. Bulk Actions Sheet */}
      <BulkActionsSheet
        visible={bulkActionDrawerVisible}
        onClose={() => setBulkActionDrawerVisible(false)}
        selectedCount={selectedLeads.length}
        users={users}
        bulkAssignUser={bulkAssignUser}
        onBulkAssignChange={setBulkAssignUser}
        onBulkAssign={handleBulkAssign}
        onBulkDelete={handleBulkDelete}
      />
      
      {/* 3. Lead Detail View */}
      <Drawer
        visible={detailViewVisible}
        onClose={() => setDetailViewVisible(false)}
        placement="right"
        width="100%"
        closable={false}
        bodyStyle={{ padding: 0 }}
      >
        <MobileLeadDetail
          lead={currentLead}
          onEdit={handleCellEdit}
          onBack={() => setDetailViewVisible(false)}
          userNames={userNames}
          fetchUserName={fetchUserName}
          onAddNote={handleAddNote}
          onCoordinatorClick={(lead) => {
            setSelectedLead(lead);
            setCoordinatorModalVisible(true);
          }}
          onDemoLanguageEdit={handleDemoLanguageEdit}
          timeOptions={timeOptions}
          isAdmin={isAdmin}
        />
      </Drawer>
      
      {/* 4. Coordinator Modal */}
      <Modal
        title="Assign Coordinator"
        open={coordinatorModalVisible}
        onCancel={() => setCoordinatorModalVisible(false)}
        footer={null}
      >
        <List
          dataSource={users}
          renderItem={user => (
            <List.Item
              key={user.id}
              onClick={() => handleCoordinatorAssign(user.id)}
              className="cursor-pointer hover:bg-gray-50 p-2 rounded transition-colors"
            >
              {user.display_name || user.username}
            </List.Item>
          )}
        />
      </Modal>
      
      {/* 5. Demo Language Modal */}
      <Modal
        title="Edit Demo Language"
        open={demoLanguageModalVisible}
        onCancel={() => {
          setDemoLanguageModalVisible(false);
          setEditingLead(null);
        }}
        footer={null}
      >
        <Select
          style={{ width: '100%' }}
          value={editingLead?.demo_language}
          onChange={handleDemoLanguageChange}
        >
          <Select.Option value="Hindi">Hindi</Select.Option>
          <Select.Option value="English">English</Select.Option>
          <Select.Option value="">None</Select.Option>
        </Select>
      </Modal>
    </div>
  );
};

// PropTypes
MobileLeadsView.propTypes = {
  currentUser: PropTypes.shape({
    user_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    username: PropTypes.string.isRequired,
    display_name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
  }).isRequired,
};

export default MobileLeadsView;