import React, { useState, useEffect, useRef } from 'react';
import { Button, DatePicker, Input, Select, Tag, Drawer, message, Modal } from 'antd';
import { X, Send, Clock, User, Phone, Book, Calendar, Star, DollarSign, Bell, MessageCircle, Clipboard, History, UserPlus } from 'lucide-react';
import moment from 'moment';
import { getUserDetailsById, updateNote, deleteNote, sendWebhookData, uploadLeadAttachment, deleteLeadAttachment } from '../apiService';
import WebhookForm from './WebhookForm';
import SourceIcon from './SourceIcon';
import { 
  Upload,
  List,
  Tooltip 
} from 'antd';
import { 
  FileText, 
  Upload as UploadIcon,
  Trash2,
  Paperclip,
} from 'lucide-react';

import { Card } from 'antd';

const { TextArea } = Input;
const { Option } = Select;

// Status Timeline Component
const StatusTimeline = ({ history, userNames, getStatusColor }) => (
  <div className="space-y-4">
    {history?.map((record, index) => (
      <div key={index} className="relative pl-6 pb-4">
        <div className="absolute left-0 top-2 w-3 h-3 bg-blue-500 rounded-full z-10" />
        {index !== history.length - 1 && (
          <div className="absolute left-1.5 top-4 w-0.5 h-full bg-blue-200 -z-10" />
        )}
        <div className="ml-4">
          <div className="text-sm text-gray-600 mb-1">
            {userNames[record.changed_by] || record.changed_by || 'System'}
            <span className="mx-2 text-gray-400">•</span>
            {moment(record.changed_at).format('DD-MMM HH:mm')}
          </div>
          <div className="flex items-center gap-2">
            <span className={`px-3 py-1 rounded-full text-sm ${getStatusColor(record.old_status)}`}>
              {record.old_status || 'New'}
            </span>
            <span className="text-gray-400">→</span>
            <span className={`px-3 py-1 rounded-full text-sm ${getStatusColor(record.new_status)}`}>
              {record.new_status}
            </span>
          </div>
        </div>
      </div>
    ))}
  </div>
);


// Assignment Timeline Component
const AssignmentTimeline = ({ history, userNames }) => (
  <div className="space-y-4">
    {history?.map((record, index) => (
      <div key={index} className="relative pl-6 pb-4">
        <div className="absolute left-0 top-2 w-3 h-3 bg-orange-500 rounded-full z-10" />
        {index !== history.length - 1 && (
          <div className="absolute left-1.5 top-4 w-0.5 h-full bg-orange-200 -z-10" />
        )}
        <div className="ml-4">
          <div className="text-sm text-gray-600 mb-1">
            {moment(record.assigned_at).format('DD-MMM HH:mm')}
          </div>
          <div className="flex items-center gap-2 font-medium">
            {record.assigned_from ? (
              <>
                {userNames[record.assigned_from] || 'Unknown'}
                <span className="text-gray-400">→</span>
              </>
            ) : 'New Assignment → '}
            {userNames[record.assigned_to] || 'Unknown'}
          </div>
        </div>
      </div>
    ))}
  </div>
);

const getStatusColor = (status) => {
  const statusStyles = {
    'Unconfirmed': 'bg-red-100 text-red-800',
    'Demo Booked': 'bg-emerald-100 text-emerald-800',
    'Demo Done': 'bg-emerald-600 text-white',
    'Demo Missed': 'bg-orange-500 text-white',
    'Follow Up': 'bg-amber-100 text-amber-800',
    'Details Shared': 'bg-blue-100 text-blue-800',
    'Offer Given': 'bg-pink-100 text-pink-800',
    'For Offer': 'bg-violet-600 text-white',
    'NP1': 'bg-indigo-900 text-white',
    'NP2': 'bg-indigo-900 text-white',
    'NP3': 'bg-indigo-900 text-white',
    'Closed': 'bg-red-900 text-white',
    'Future Follow': 'bg-cyan-800 text-white',
    'Admission': 'bg-emerald-800 text-white'
  };

  return statusStyles[status] || 'bg-gray-100 text-gray-800';
};

const NoteBubble = ({ note, onDelete, onEdit, isEditing, editContent, setEditContent, handleSave }) => (
  <div className="bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow p-4">
    <div className="flex justify-between items-start mb-3">
      <div className="flex items-center gap-2">
        <div className="w-8 h-8 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center">
          {note.created_by_name?.charAt(0).toUpperCase() || '?'}
        </div>
        <div>
          <div className="font-medium">{note.created_by_name || 'Unknown'}</div>
          <div className="text-sm text-gray-500">
            {moment(note.created_at).format('DD-MMM-YYYY HH:mm')}
          </div>
        </div>
      </div>
      <div className="flex gap-2">
        <button
          onClick={(e) => {
            e.stopPropagation();
            onDelete(note.id, e);
          }}
          className="p-1.5 text-gray-400 hover:text-red-500 hover:bg-red-50 rounded-full transition-colors"
        >
          <X className="h-4 w-4" />
        </button>
      </div>
    </div>
    
    {isEditing ? (
      <TextArea
        value={editContent}
        onChange={(e) => setEditContent(e.target.value)}
        onBlur={() => handleSave(note.id, editContent)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSave(note.id, editContent);
          }
        }}
        autoSize={{ minRows: 2, maxRows: 6 }}
        className="mt-2"
      />
    ) : (
      <div 
        className="text-gray-800 whitespace-pre-wrap cursor-pointer hover:bg-gray-50 p-2 rounded-lg transition-colors"
        onClick={() => onEdit(note.id)}
      >
        {note.note_content}
      </div>
    )}
  </div>
);

const QuickNoteSection = ({ category, notes, selectedNotes, onNoteClick }) => (
  <div className="flex flex-col">
    <h4 className="text-sm font-medium text-gray-600 mb-2 sticky top-0 bg-gray-50 py-2 z-10 border-b">
      {category.title}
    </h4>
    <div className="space-y-1.5">
      {category.items.map((note) => (
        <div 
          key={note.id}
          onClick={() => onNoteClick(note.text)}
          className={`cursor-pointer rounded-xl text-sm transition-all p-2.5 ${
            selectedNotes.includes(note.text)
              ? 'bg-blue-500 text-white shadow-md hover:bg-blue-600'
              : 'bg-white hover:bg-gray-50 shadow-sm hover:shadow-md'
          }`}
        >
          {note.text}
        </div>
      ))}
    </div>
  </div>
);

// Updated InfoCard with gradient backgrounds
const InfoCard = ({ icon: Icon, label, children, className = "", gradient = "blue" }) => {
  const gradients = {
    blue: "bg-gradient-to-br from-blue-50 to-indigo-50 border-blue-100",
    purple: "bg-gradient-to-br from-purple-50 to-pink-50 border-purple-100",
    green: "bg-gradient-to-br from-emerald-50 to-teal-50 border-emerald-100",
    orange: "bg-gradient-to-br from-orange-50 to-amber-50 border-orange-100",
    red: "bg-gradient-to-br from-red-50 to-pink-50 border-red-100",
    cyan: "bg-gradient-to-br from-cyan-50 to-blue-50 border-cyan-100"
  };

  return (
    <Card 
      className={`${gradients[gradient]} border shadow-sm hover:shadow-md transition-shadow ${className}`}
      title={
        <div className="flex items-center gap-2 text-gray-700">
          <Icon className="h-4 w-4" />
          <span className="text-sm font-medium">{label}</span>
        </div>
      }
    >
      {children}
    </Card>
  );
};

const LeadDetailsDrawer = ({
  lead,
  visible,
  onClose,
  onEdit,
  onDemoLanguageEdit,
  timeOptions = [],
  getLanguageColor,
  onAddNote,
  onCoordinatorClick,
  refreshLeads
}) => {
  const [newNote, setNewNote] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [selectedNotes, setSelectedNotes] = useState([]);
  const [editingName, setEditingName] = useState(false);
  const [tempName, setTempName] = useState('');
  const [userNames, setUserNames] = useState({});
  const [editingNoteId, setEditingNoteId] = useState(null);
  const [editedNoteContent, setEditedNoteContent] = useState('');
  const [isWebhookModalVisible, setIsWebhookModalVisible] = useState(false);
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [attachmentLabel, setAttachmentLabel] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  const textAreaRef = useRef(null);
  const editInputRef = useRef(null);

  // All quick notes data
  const allQuickNotesData = [
    {
      title: "Lead Status",
      items: [
        { id: 1, text: 'Not interested' },
        { id: 2, text: 'Not picked' },
        { id: 3, text: 'Budget issues' },
        { id: 4, text: 'Not interested after Demo' },
        { id: 5, text: 'Will pay after' },
        { id: 6, text: 'Wants discount' },
        { id: 17, text: 'Call disconnected' },
        { id: 18, text: 'Disconnected after listening NIL' },
        { id: 34, text: 'Not on WhatsApp' },
        { id: 35, text: 'Not Enquired' },
      ]
    },
    {
      title: "Communication",
      items: [
        { id: 7, text: 'is busy asked to call after/later' },
        { id: 8, text: 'Connected on WhatsApp' },
        { id: 9, text: 'Details shared' },
        { id: 19, text: 'Said someone else enquired' },
        { id: 20, text: 'Wants to start next month' },
        { id: 21, text: 'Busy in office, Call later' },
        { id: 22, text: 'Busy in function, Call later' },
        { id: 23, text: 'Wants free classes' },
        { id: 24, text: 'Switched off/unreachable' },
        { id: 26, text: 'Hindi speaker' },
        { id: 27, text: 'Cannot make sentences' },
        { id: 28, text: 'Casual, not serious' },
        { id: 29, text: 'Will connect after:' },
        { id: 30, text: 'Working Professional' },
        { id: 31, text: 'Basic Speaker/Average Speaker' },
        { id: 32, text: 'Student/Studying' },
        { id: 33, text: 'Invalid Number/Switched off/No Incoming' }
      ]
    },
    {
      title: "Course Types",
      items: [
        { id: 10, text: 'Foundation PT' },
        { id: 11, text: 'Foundation group' },
        { id: 12, text: 'Advanced PT' },
        { id: 13, text: 'Advanced Group' },
        { id: 14, text: 'Business PT' },
        { id: 15, text: 'One month' },
        { id: 16, text: 'Three month' },
        { id: 25, text: 'Kids Communication classes' }
      ]
    }
  ];

  // Filtered quick notes for display
  const displayQuickNotes = allQuickNotesData.map(category => ({
    title: category.title,
    items: category.items.filter(item => [1, 2, 3, 4, 8, 9, 17, 18, 21, 23, 31, 33,34,35].includes(item.id))
  })).filter(category => category.items.length > 0);

  // All quick notes for autocomplete
  const allQuickNotes = allQuickNotesData.flatMap(category => 
    category.items.map(item => ({
      ...item,
      category: category.title
    }))
  );

  useEffect(() => {
    if (lead?.name) {
      setTempName(lead.name);
    }
  }, [lead]);

  useEffect(() => {
    if (!visible) {
      setNewNote('');
      setSuggestions([]);
      setEditingName(false);
      setSelectedNotes([]);
      setEditingNoteId(null);
      setEditedNoteContent('');
    }
  }, [visible]);

  useEffect(() => {
    if (editingNoteId && editInputRef.current) {
      editInputRef.current.focus();
    }
  }, [editingNoteId]);

  const fetchUserName = async (userId) => {
    if (!userNames[userId]) {
      try {
        const userDetails = await getUserDetailsById(userId);
        setUserNames(prev => ({
          ...prev,
          [userId]: userDetails.display_name
        }));
      } catch (error) {
        console.error('Error fetching user details:', error);
        setUserNames(prev => ({
          ...prev,
          [userId]: 'Unknown'
        }));
      }
    }
  };

  useEffect(() => {
    if (visible && lead) {
      const userIds = new Set();
      
      lead.status_history?.forEach(history => {
        if (history.changed_by) userIds.add(history.changed_by);
      });
      
      lead.assignment_history?.forEach(assignment => {
        if (assignment.assigned_from) userIds.add(assignment.assigned_from);
        if (assignment.assigned_to) userIds.add(assignment.assigned_to);
      });

      userIds.forEach(userId => fetchUserName(userId));
    }
  }, [visible, lead]);

  const handleEditNote = async (noteId, content) => {
    if (!content.trim()) {
      message.error('Note content cannot be empty');
      return;
    }
    
    try {
      await updateNote(lead.id, noteId, content);
      message.success('Note updated successfully');
      refreshLeads();
      setEditingNoteId(null);
      setEditedNoteContent('');
    } catch (error) {
      console.error('Error updating note:', error);
      message.error('Failed to update note');
    }
  };

  const handleDeleteNote = async (noteId, e) => {
    e.stopPropagation();
    try {
      await deleteNote(lead.id, noteId);
      message.success('Note deleted successfully');
      refreshLeads();
    } catch (error) {
      console.error('Error deleting note:', error);
      message.error('Failed to delete note');
    }
  };

  const handleDeleteAttachment = async (attachmentId) => {
    try {
      await deleteLeadAttachment(lead.id, attachmentId);
      message.success('Attachment deleted successfully');
      refreshLeads();
    } catch (error) {
      console.error('Error deleting attachment:', error);
      message.error('Failed to delete attachment');
    }
  };

  const handleWebhookSubmit = async (webhookData) => {
    try {
      await sendWebhookData(webhookData);
      message.success('Data sent for allotment successfully');
      setIsWebhookModalVisible(false);
    } catch (error) {
      console.error('Error sending data to webhook:', error);
      message.error('Failed to send data for allotment');
    }
  };

  const handleFileUpload = async (e) => {
    if (e) {
      e.preventDefault();
    }
  
    if (!selectedFile || !attachmentLabel.trim()) {
      message.error('Both file and label are required');
      return;
    }
  
    setIsUploading(true);
  
    try {
      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('label', attachmentLabel.trim());
  
      await uploadLeadAttachment(lead.id, formData);
      message.success('File uploaded successfully');
      refreshLeads();
      handleCloseUploadModal();
    } catch (error) {
      console.error('Upload error:', error);
      message.error('Failed to upload file: ' + (error.response?.data?.message || error.message));
    } finally {
      setIsUploading(false);
    }
  };

  const handleCloseUploadModal = () => {
    setIsUploadModalVisible(false);
    setSelectedFile(null);
    setAttachmentLabel('');
    setIsUploading(false);
  };

  const FilePreviewModal = ({ visible, onClose, file }) => {
    const isImage = file?.file_type?.startsWith('image/');
    const isPDF = file?.file_type === 'application/pdf';
  
    return (
      <Modal
        title={
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <FileText className="h-5 w-5 text-blue-500" />
              <span className="font-medium truncate">{file?.file_name}</span>
            </div>
            <div className="flex items-center gap-2">
              <Button
                type="link"
                href={file?.file_url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:text-blue-600 text-sm"
                icon={<UploadIcon className="h-4 w-4" />}
              >
                Open in new tab
              </Button>
            </div>
          </div>
        }
        open={visible}
        onCancel={onClose}
        footer={null}
        width={800}
        bodyStyle={{ padding: '20px' }}
        className="file-preview-modal"
      >
        <div className="mt-4 flex justify-center">
          {isImage && (
            <div className="max-w-full max-h-[70vh] overflow-auto">
              <img 
                src={file?.file_url} 
                alt={file?.file_name}
                className="object-contain w-full h-full"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E";
                }}
              />
            </div>
          )}
          {isPDF && (
            <div className="w-full h-[70vh] border border-gray-200 rounded-lg overflow-hidden">
              <iframe
                src={`${file?.file_url}#toolbar=0`}
                title={file?.file_name}
                className="w-full h-full border-0"
                sandbox="allow-scripts allow-same-origin"
              />
            </div>
          )}
          {!isImage && !isPDF && (
            <div className="text-center py-8 text-gray-500">
              <FileText className="h-16 w-16 mx-auto mb-4 text-gray-400" />
              <p className="mb-2">Preview not available for this file type</p>
              <p className="text-sm text-gray-400 mb-4">({file?.file_type || 'Unknown type'})</p>
              <Button
                type="primary"
                href={file?.file_url}
                target="_blank"
                rel="noopener noreferrer"
                icon={<UploadIcon className="h-4 w-4" />}
              >
                Download to view
              </Button>
            </div>
          )}
        </div>
      </Modal>
    );
  };

  const UploadModal = ({ 
    visible, 
    onCancel, 
    onUpload, 
    attachmentLabel,
    onLabelChange,
    selectedFile,
    onFileSelect,
    isUploading 
  }) => (
    <Modal
      title={
        <div className="flex items-center gap-2">
          <UploadIcon className="h-5 w-5" />
          <span>Upload Attachment</span>
        </div>
      }
      open={visible}
      onCancel={onCancel}
      maskClosable={false}
      destroyOnClose={false}
      footer={[
        <Button key="cancel" onClick={onCancel} disabled={isUploading}>
          Cancel
        </Button>,
        <Button 
          key="upload" 
          type="primary"
          onClick={onUpload}
          disabled={!selectedFile || !attachmentLabel.trim() || isUploading}
          loading={isUploading}
        >
          {isUploading ? 'Uploading...' : 'Upload'}
        </Button>
      ]}
    >
      <form onSubmit={handleFileUpload} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Attachment Label *
          </label>
          <Input
            value={attachmentLabel}
            onChange={(e) => onLabelChange(e.target.value)}
            placeholder="Enter a descriptive label"
            maxLength={100}
            disabled={isUploading}
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            File *
          </label>
          <Upload
            beforeUpload={(file) => {
              const isValidSize = file.size / 1024 / 1024 < 10;
              if (!isValidSize) {
                message.error('File must be smaller than 10MB');
                return Upload.LIST_IGNORE;
              }
              
              const isValidType = [
                'image/jpeg', 
                'image/png', 
                'application/pdf', 
                'application/msword', 
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              ].includes(file.type);
              if (!isValidType) {
                message.error('Invalid file type. Supported types: JPG, PNG, PDF, DOC, DOCX');
                return Upload.LIST_IGNORE;
              }
              
              onFileSelect(file);
              return false;
            }}
            onRemove={() => onFileSelect(null)}
            maxCount={1}
            fileList={selectedFile ? [selectedFile] : []}
            disabled={isUploading}
          >
            <Button icon={<UploadIcon className="h-4 w-4" />} disabled={isUploading}>
              Select File
            </Button>
          </Upload>
        </div>
      </form>
    </Modal>
  );

  const findSuggestions = (text) => {
    const words = text.split(/[;,.\s]+/);
    const currentWord = words[words.length - 1].toLowerCase();

    if (currentWord.length < 2) {
      setSuggestions([]);
      return;
    }

    const matches = allQuickNotes.filter(note =>
      note.text.toLowerCase().includes(currentWord)
    );

    setSuggestions(matches.slice(0, 5));
    setActiveSuggestion(0);
  };

  const handleNoteClick = (noteText) => {
    let updatedNotes;
    if (selectedNotes.includes(noteText)) {
      updatedNotes = selectedNotes.filter(note => note !== noteText);
    } else {
      updatedNotes = [...selectedNotes, noteText];
    }
    setSelectedNotes(updatedNotes);

    const newText = newNote ? 
      (newNote.includes(noteText) ? 
        newNote.split('; ').filter(part => part !== noteText).join('; ') :
        `${newNote}; ${noteText}`
      ) : noteText;
    
    setNewNote(newText);
  };

  const handleTextChange = (e) => {
    const newValue = e.target.value;
    const newPosition = e.target.selectionStart || 0;
    
    setNewNote(newValue);
    setCursorPosition(newPosition);
    findSuggestions(newValue);
  };

  const applySuggestion = (suggestionText) => {
    const textBeforeCursor = newNote.slice(0, cursorPosition);
    const textAfterCursor = newNote.slice(cursorPosition);
    const words = textBeforeCursor.split(/([;,.\s]+)/);
    
    words[words.length - 1] = suggestionText;
    
    const updatedNote = words.join('') + textAfterCursor;
    setNewNote(updatedNote);
    setSuggestions([]);

    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  };

  const handleKeyDown = (e) => {
    if (!suggestions.length) return;

    switch(e.key) {
      case 'ArrowUp':
        e.preventDefault();
        setActiveSuggestion(prev => (prev > 0 ? prev - 1 : suggestions.length - 1));
        break;
      case 'ArrowDown':
        e.preventDefault();
        setActiveSuggestion(prev => (prev < suggestions.length - 1 ? prev + 1 : 0));
        break;
      case 'Enter':
        if (!e.shiftKey) {
          e.preventDefault();
          applySuggestion(suggestions[activeSuggestion].text);
        }
        break;
      case 'Escape':
        setSuggestions([]);
        break;
      default:
        break;
    }
  };

  const handleNameSave = () => {
    if (lead?.id) {
      onEdit(lead.id, 'name', tempName);
    }
    setEditingName(false);
  };

  const handleAddNote = async () => {
    if (!newNote.trim()) {
      message.error('Note cannot be empty');
      return;
    }
    
    try {
      await onAddNote(lead.id, newNote);
      setNewNote('');
      setSelectedNotes([]);
      refreshLeads();
      message.success('Note added successfully');
    } catch (error) {
      console.error('Error adding note:', error);
      message.error('Failed to add note');
    }
  };

  // Render the main content sections with gradients
  const renderBasicInfo = () => (
    <InfoCard icon={User} label="Lead Information" gradient="blue" className="col-span-2">
      <div className="grid grid-cols-2 gap-4">
        <div className="space-y-3">
          <div className="flex gap-2 items-center">
            <Phone className="h-4 w-4 text-blue-500" />
            <Input
              value={lead.phone || ''}
              onChange={(e) => lead?.id && onEdit(lead.id, 'phone', e.target.value)}
              placeholder="Phone number"
              className="flex-1"
            />
          </div>
          <div className="flex gap-2 items-center">
            <Book className="h-4 w-4 text-blue-500" />
            <Input
              value={lead.course || ''}
              onChange={(e) => lead?.id && onEdit(lead.id, 'course', e.target.value)}
              placeholder="Course details"
              className="flex-1"
            />
          </div>
        </div>

        <div className="space-y-3">
          <div className="flex gap-2 items-center">
            <Calendar className="h-4 w-4 text-blue-500" />
            <DatePicker
              value={lead.booking_date ? moment(lead.booking_date, 'DD-MMM-YYYY') : null}
              format="DD-MMM-YYYY"
              onChange={(date) => lead?.id && onEdit(lead.id, 'booking_date', date ? date.format('DD-MMM-YYYY') : null)}
              className="flex-1"
            />
          </div>
          <div className="flex gap-2 items-center">
            <Clock className="h-4 w-4 text-blue-500" />
            <Select
              value={lead.booking_time}
              onChange={(value) => lead?.id && onEdit(lead.id, 'booking_time', value)}
              className="flex-1"
            >
              {timeOptions.map(time => (
                <Option key={time} value={time}>{time}</Option>
              ))}
            </Select>
          </div>
        </div>
      </div>
    </InfoCard>
  );

  const renderFeeAndSchedule = () => (
    <div className="grid grid-cols-2 gap-4">
      <InfoCard icon={DollarSign} label="Fee Details" gradient="green">
        <div className="grid grid-cols-2 gap-2 mb-2">
          <div className="relative">
            <Input
              value={lead.fee_due || ''}
              onChange={(e) => lead?.id && onEdit(lead.id, 'fee_due', e.target.value)}
              prefix="₹"
              placeholder="Fee Due"
              className="h-9 bg-white"
            />
          </div>
          <div className="relative">
            <Input
              value={lead.revenue || ''}
              onChange={(e) => lead?.id && onEdit(lead.id, 'revenue', e.target.value)}
              prefix="₹"
              placeholder="Revenue"
              className="h-9 bg-white"
            />
          </div>
        </div>
        <DatePicker
          value={lead.fee_due_date ? moment(lead.fee_due_date, 'YYYY-MM-DD') : null}
          onChange={(date) => lead?.id && onEdit(lead.id, 'fee_due_date', date ? date.format('YYYY-MM-DD') : null)}
          className="w-full bg-white h-9"
          placeholder="Due Date"
          suffixIcon={<Calendar className="h-4 w-4 text-gray-400" />}
        />
      </InfoCard>

      <InfoCard icon={Bell} label="Call Schedule" gradient="purple">
        <div className="space-y-2">
          <DatePicker
            showTime={{ format: 'hh:mm A' }}
            value={lead.call_date ? moment(lead.call_date, 'D-MMM-YYYY hh:mm A') : null}
            format="DD-MMM-YYYY hh:mm A"
            onChange={(date, dateString) => lead?.id && onEdit(lead.id, 'call_date', dateString)}
            className="w-full bg-white h-9"
            placeholder="Last Called"
            suffixIcon={<Calendar className="h-4 w-4 text-gray-400" />}
          />
          <DatePicker
            showTime={{ format: 'hh:mm A' }}
            value={lead.next_call_date ? moment(lead.next_call_date, 'D-MMM-YYYY hh:mm A') : null}
            format="DD-MMM-YYYY hh:mm A"
            onChange={(date, dateString) => lead?.id && onEdit(lead.id, 'next_call_date', dateString)}
            className="w-full bg-white h-9"
            placeholder="Next Call"
            suffixIcon={<Calendar className="h-4 w-4 text-gray-400" />}
          />
        </div>
      </InfoCard>
    </div>
  );
  
  const renderQuickNotes = () => (
    <InfoCard icon={MessageCircle} label="Quick Notes" gradient="purple" className="space-y-4">
      <div className="grid grid-cols-2 gap-4">
        {displayQuickNotes.map((category, index) => (
          <QuickNoteSection
            key={index}
            category={category}
            notes={category.items}
            selectedNotes={selectedNotes}
            onNoteClick={handleNoteClick}
          />
        ))}
      </div>

      <div className="relative mt-4">
        <TextArea
          ref={textAreaRef}
          value={newNote}
          onChange={handleTextChange}
          onKeyDown={handleKeyDown}
          placeholder="Type to search quick notes or enter custom text..."
          className="w-full p-3 border rounded-xl shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-white"
          autoSize={{ minRows: 2, maxRows: 6 }}
        />
        
        {suggestions.length > 0 && (
          <div className="absolute left-0 right-0 mt-1 bg-white border rounded-xl shadow-lg z-50 max-h-48 overflow-y-auto">
            {suggestions.map((suggestion, index) => (
              <div
                key={suggestion.id}
                className={`p-3 cursor-pointer ${
                  index === activeSuggestion 
                    ? 'bg-blue-50 border-l-4 border-blue-500' 
                    : 'hover:bg-gray-50'
                }`}
                onClick={() => applySuggestion(suggestion.text)}
              >
                <div className="font-medium">{suggestion.text}</div>
                <div className="text-xs text-gray-500">{suggestion.category}</div>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="flex flex-wrap gap-2">
        {selectedNotes.map((note) => (
          <Tag
            key={note}
            closable
            onClose={() => handleNoteClick(note)}
            className="bg-blue-50 border-blue-200 text-blue-800 px-3 py-1 rounded-full"
          >
            {note}
          </Tag>
        ))}
      </div>

      <Button 
        type="primary"
        onClick={handleAddNote}
        size="large"
        className="w-full mt-4 bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 rounded-xl h-12 flex items-center justify-center gap-2"
      >
        <MessageCircle className="h-5 w-5" />
        Add Note
      </Button>
    </InfoCard>
  );

  const renderNotesHistory = () => (
    <InfoCard icon={History} label="Notes History" gradient="blue" className="space-y-4">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-medium">Latest Updates</h3>
        <Button
          type="primary"
          icon={<Send className="h-4 w-4" />}
          onClick={() => setIsWebhookModalVisible(true)}
          className="bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700"
        >
          Send for Allotment
        </Button>
      </div>

      <div className="space-y-4">
        {lead.notes?.map((note) => (
          <NoteBubble
            key={note.id}
            note={note}
            onDelete={handleDeleteNote}
            onEdit={(id) => {
              setEditingNoteId(id);
              setEditedNoteContent(note.note_content);
            }}
            isEditing={editingNoteId === note.id}
            editContent={editedNoteContent}
            setEditContent={setEditedNoteContent}
            handleSave={handleEditNote}
          />
        ))}
        {(!lead.notes || lead.notes.length === 0) && (
          <div className="text-center text-gray-500 py-8 bg-gray-50 rounded-xl">
            <MessageCircle className="h-8 w-8 mx-auto mb-2 text-gray-400" />
            No notes yet
          </div>
        )}
      </div>

      <Modal
        title={
          <div className="flex items-center gap-2">
            <Send className="h-5 w-5" />
            <span>Send for Allotment</span>
          </div>
        }
        open={isWebhookModalVisible}
        onCancel={() => setIsWebhookModalVisible(false)}
        footer={null}
        width={600}
        className="rounded-xl"
      >
        <WebhookForm
          leadName={lead.name}
          phone={lead.phone}
          email={lead.email}
          coordinatorName={lead.coordinator_name}
          onSubmit={handleWebhookSubmit}
        />
      </Modal>
    </InfoCard>
  );

  return (
    <Drawer
  title={
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <div className="space-y-1">
              {editingName ? (
                <div className="flex items-center gap-2">
                  <SourceIcon source={lead.source} />
                  <Input
                    value={tempName}
                    onChange={(e) => setTempName(e.target.value)}
                    onPressEnter={handleNameSave}
                    onBlur={handleNameSave}
                    autoFocus
                    className="text-xl font-bold"
                  />
                </div>
              ) : (
                <h2 
                  className="text-xl font-bold m-0 cursor-pointer hover:bg-gray-50 p-2 rounded-xl group flex items-center gap-2"
                  onClick={() => setEditingName(true)}
                >
                  <SourceIcon source={lead.source} />
                  {lead.name || 'Unnamed Lead'}
                  <span className="text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity">
                    (Click to edit)
                  </span>
                </h2>
              )}
              <div className="text-sm text-gray-500 flex items-center gap-2">
                <Clock className="h-4 w-4" />
                Created: {lead.date ? moment(lead.date).format('DD-MMM-YYYY') : 'N/A'}
              </div>
              </div>
        <div className="flex items-center gap-4">
          <Select
            value={lead.priority}
            onChange={(value) => lead?.id && onEdit(lead.id, 'priority', value)}
            className="w-32 bg-white"
            size="large"
          >
            <Option value="Low">
              <span className="flex items-center gap-2">
                <div className="w-2 h-2 rounded-full bg-green-500" />
                Low
              </span>
            </Option>
            <Option value="Medium">
              <span className="flex items-center gap-2">
                <div className="w-2 h-2 rounded-full bg-yellow-500" />
                Medium
              </span>
            </Option>
            <Option value="High">
              <span className="flex items-center gap-2">
                <div className="w-2 h-2 rounded-full bg-red-500" />
                High
              </span>
            </Option>
          </Select>
          <Button 
            onClick={() => lead?.id && onCoordinatorClick(lead)}
            className="flex items-center gap-2 bg-white h-10 px-4"
            size="large"
          >
            <UserPlus className="h-4 w-4" />
            {lead.coordinator_name || 'Assign Coordinator'}
          </Button>
          <Button 
            type="text"
            icon={<X className="h-5 w-5" />}
            onClick={onClose}
            className="hover:bg-gray-100 rounded-full h-10 w-10 flex items-center justify-center ml-2"
          />
        </div>
      </div>
          
          {/* Status and Language Section */}
          <div className="flex justify-between items-center bg-gradient-to-r from-blue-50 to-indigo-50 p-3 rounded-xl shadow-sm border border-blue-100">
            <Select
              value={lead.status}
              onChange={(value) => lead?.id && onEdit(lead.id, 'status', value)}
              className="w-64 bg-white"
            >
              {[
                'Unconfirmed', 'Demo Booked', 'Demo Done', 'Demo Missed',
                'Follow Up', 'Details Shared', 'Offer Given', 'For Offer',
                'NP1', 'NP2', 'NP3', 'Closed', 'Future Follow', 'Admission'
              ].map(status => (
                <Option key={status} value={status}>
                  <span className={`px-3 py-1 rounded-full ${getStatusColor(status)}`}>
                    {status}
                  </span>
                </Option>
              ))}
            </Select>
            <Tag
              color={getLanguageColor(lead.demo_language)}
              className="cursor-pointer text-base px-4 py-1 rounded-full"
              onClick={() => lead?.id && onDemoLanguageEdit(lead)}
            >
              {lead.demo_language || 'No Language'}
            </Tag>
          </div>
        </div>
      }
      placement="right"
      width={1200}
      open={visible}
      onClose={onClose}
      closeIcon={null} 
      className="lead-details-drawer"
    >
      <div className="flex gap-6">
        {/* Left Column */}
        <div className="flex-1 space-y-4">
          {renderBasicInfo()}
          {renderFeeAndSchedule()}
          
          {/* Updated Attachments Section */}
          <InfoCard icon={Paperclip} label="Attachments" gradient="cyan">
            <div className="flex gap-4">
              <div className="w-1/3">
                <Button 
                  icon={<UploadIcon className="h-4 w-4" />}
                  onClick={() => setIsUploadModalVisible(true)}
                  className="w-full bg-white mb-3"
                >
                  Upload File
                </Button>
              </div>
              
              <div className="w-2/3">
                <div className="grid grid-cols-1 gap-2 max-h-[150px] overflow-y-auto">
                  {lead.attachments && lead.attachments.length > 0 ? (
                    lead.attachments.map((attachment) => (
                      <div
                        key={attachment.id}
                        className="flex items-center justify-between p-2 bg-white rounded-lg hover:bg-gray-50 cursor-pointer border border-gray-100"
                        onClick={() => {
                          setPreviewFile(attachment);
                          setIsPreviewVisible(true);
                        }}
                      >
                        <div className="flex items-center gap-2 min-w-0">
                          <FileText className="h-4 w-4 text-blue-500 flex-shrink-0" />
                          <div className="truncate">
                            <div className="text-sm font-medium text-gray-900 truncate">
                              {attachment.label || attachment.file_name}
                            </div>
                            <div className="text-xs text-gray-500">
                              {moment(attachment.uploaded_at).format('DD MMM YYYY, HH:mm')}
                            </div>
                          </div>
                        </div>
                        <Button
                          type="text"
                          icon={<Trash2 className="h-4 w-4 text-gray-400 hover:text-red-500" />}
                          onClick={(e) => {
                            e.stopPropagation();
                            Modal.confirm({
                              title: 'Delete Attachment',
                              content: 'Are you sure you want to delete this attachment?',
                              okText: 'Delete',
                              okType: 'danger',
                              onOk: () => handleDeleteAttachment(attachment.id)
                            });
                          }}
                        />
                      </div>
                    ))
                  ) : (
                    <div className="text-center py-4 text-gray-500 bg-gray-50 rounded-lg">
                      <Paperclip className="h-5 w-5 mx-auto mb-1 text-gray-400" />
                      <p className="text-sm">No attachments yet</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </InfoCard>

          {/* Timeline sections with reduced padding */}
          <InfoCard icon={History} label="Status Timeline" gradient="cyan" className="p-3">
            <StatusTimeline 
              history={lead.status_history?.slice().reverse()} 
              userNames={userNames}
              getStatusColor={getStatusColor}
            />
          </InfoCard>

          <InfoCard icon={UserPlus} label="Assignment Timeline" gradient="orange" className="p-3">
            <AssignmentTimeline 
              history={lead.assignment_history?.slice().reverse()}
              userNames={userNames}
            />
          </InfoCard>
        </div>

        {/* Right Column */}
        <div className="flex-1 space-y-4">
          {renderQuickNotes()}
          {renderNotesHistory()}
        </div>
      </div>

      {/* Modals remain unchanged */}
      <UploadModal
        visible={isUploadModalVisible}
        onCancel={handleCloseUploadModal}
        onUpload={handleFileUpload}
        attachmentLabel={attachmentLabel}
        onLabelChange={setAttachmentLabel}
        selectedFile={selectedFile}
        onFileSelect={setSelectedFile}
        isUploading={isUploading}
      />

      <FilePreviewModal
        visible={isPreviewVisible}
        onClose={() => {
          setIsPreviewVisible(false);
          setPreviewFile(null);
        }}
        file={previewFile}
      />
    </Drawer>
  );
};

export default LeadDetailsDrawer;