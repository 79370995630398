import React, { useState, useEffect, useCallback } from 'react';
import { Card, Avatar, Typography, Button, Modal, Form, Input, Upload, message, Row, Col, DatePicker, List } from 'antd';
import { UserOutlined, EditOutlined, UploadOutlined, FacebookOutlined, InstagramOutlined, LinkedinOutlined, DeleteOutlined } from '@ant-design/icons';
import { getUserDetailsById, getSocialUpdates, updateUserProfile } from '../apiService';
import { useParams } from 'react-router-dom';
import moment from 'moment';

const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;

const UserProfile = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [form] = Form.useForm();
  const { userId = 'me' } = useParams();
  const [recentUpdates, setRecentUpdates] = useState([]);
  const [fileList, setFileList] = useState([]);

  const fetchUserProfile = useCallback(async () => {
    try {
      setLoading(true);
      
      // Added fallback for undefined userId
      const effectiveUserId = userId === 'undefined' || !userId ? 'me' : userId;
      
      const userData = await getUserDetailsById(effectiveUserId);
      setUser(userData);
      form.setFieldsValue({
        ...userData,
        date_of_birth: userData.date_of_birth ? moment(userData.date_of_birth) : null,
      });
      if (userData.profile_image_url) {
        setFileList([
          {
            uid: '-1',
            name: 'profile_image.png',
            status: 'done',
            url: userData.profile_image_url,
          },
        ]);
      } else {
        setFileList([]);
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
      message.error('Failed to load user profile');
    } finally {
      setLoading(false);
    }
  }, [userId, form]);

  const fetchRecentUpdates = useCallback(async () => {
    try {
      // Added fallback for undefined userId
      const effectiveUserId = userId === 'undefined' || !userId ? 'me' : userId;
      
      const updates = await getSocialUpdates({ userId: effectiveUserId, limit: 10 });
      setRecentUpdates(updates);
    } catch (error) {
      console.error('Error fetching recent updates:', error);
      message.error('Failed to load recent updates');
    }
  }, [userId]);

  useEffect(() => {
    fetchUserProfile();
    fetchRecentUpdates();
  }, [fetchUserProfile, fetchRecentUpdates]);

  const handleEditProfile = async (values) => {
    try {
      const formData = new FormData();
      Object.keys(values).forEach(key => {
        if (key === 'profile_image') {
          if (fileList.length > 0 && fileList[0].originFileObj) {
            formData.append(key, fileList[0].originFileObj);
          }
        } else if (key === 'date_of_birth' && values[key]) {
          formData.append(key, values[key].format('YYYY-MM-DD'));
        } else if (values[key] !== undefined && values[key] !== null) {
          formData.append(key, values[key]);
        }
      });

      // If the file list is empty, it means the user wants to remove the profile image
      if (fileList.length === 0) {
        formData.append('remove_profile_image', '1');
      }

      const updatedUser = await updateUserProfile(userId, formData);
      setUser(updatedUser);
      message.success('Profile updated successfully');
      setEditModalVisible(false);
      fetchUserProfile(); // Refresh the profile data
    } catch (error) {
      console.error('Error updating user profile:', error);
      message.error('Failed to update profile: ' + (error.response?.data?.message || error.message));
    }
  };

  const handleImageChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const handleImageRemove = () => {
    setFileList([]);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <div>User not found</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <Row gutter={[24, 24]}>
        <Col xs={24} md={8}>
          <Card hoverable className="user-profile-card">
            <div className="text-center mb-4">
              <Avatar size={128} src={user.profile_image_url} icon={<UserOutlined />} />
            </div>
            <Title level={3} className="text-center mb-2">{user.display_name}</Title>
            <Text className="block text-center mb-4">{user.role}</Text>
            <Button
              type="primary"
              icon={<EditOutlined />}
              onClick={() => setEditModalVisible(true)}
              className="w-full"
            >
              Edit Profile
            </Button>
          </Card>
        </Col>
        <Col xs={24} md={16}>
          <Card hoverable className="user-details-card">
            <Title level={4}>User Details</Title>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Text strong>Email:</Text> {user.email}
              </Col>
              <Col span={12}>
                <Text strong>Date of Birth:</Text> {user.date_of_birth}
              </Col>
              <Col span={12}>
                <Text strong>Educational Qualification:</Text> {user.educational_qualification}
              </Col>
              <Col span={12}>
                <Text strong>Designation:</Text> {user.designation}
              </Col>
            </Row>
            <Paragraph className="mt-4">
              <Text strong>Bio:</Text> {user.bio}
            </Paragraph>
            <div className="mt-4">
              <Button type="link" href={user.facebook_link} target="_blank" icon={<FacebookOutlined />}>Facebook</Button>
              <Button type="link" href={user.instagram_link} target="_blank" icon={<InstagramOutlined />}>Instagram</Button>
              <Button type="link" href={user.linkedin_link} target="_blank" icon={<LinkedinOutlined />}>LinkedIn</Button>
            </div>
          </Card>
        </Col>
      </Row>

      <Card hoverable className="mt-8 recent-updates-card">
        <Title level={4}>Recent Updates</Title>
        <List
          itemLayout="horizontal"
          dataSource={recentUpdates}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src={item.user_avatar} />}
                title={item.content}
                description={moment(item.created_at).fromNow()}
              />
            </List.Item>
          )}
        />
      </Card>

      <Modal
        title="Edit Profile"
        visible={editModalVisible}
        onCancel={() => setEditModalVisible(false)}
        footer={null}
      >
        <Form form={form} onFinish={handleEditProfile} layout="vertical">
          <Form.Item name="display_name" label="Name" rules={[{ required: true, message: 'Please input your name!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="date_of_birth" label="Date of Birth">
            <DatePicker />
          </Form.Item>
          <Form.Item name="educational_qualification" label="Educational Qualification">
            <Input />
          </Form.Item>
          <Form.Item name="designation" label="Designation">
            <Input />
          </Form.Item>
          <Form.Item name="facebook_link" label="Facebook Link">
            <Input />
          </Form.Item>
          <Form.Item name="instagram_link" label="Instagram Link">
            <Input />
          </Form.Item>
          <Form.Item name="linkedin_link" label="LinkedIn Link">
            <Input />
          </Form.Item>
          <Form.Item name="bio" label="Bio">
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item name="profile_image" label="Profile Image">
            <Upload
              listType="picture-card"
              fileList={fileList}
              onChange={handleImageChange}
              onRemove={handleImageRemove}
              beforeUpload={() => false}
              maxCount={1}
            >
              {fileList.length < 1 && (
                <div>
                  <UploadOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              )}
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save Changes
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default UserProfile;