import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { 
  Button, 
  Input, 
  message, 
  Typography, 
  Avatar,
  Popover,
  List,
  Tag
} from 'antd';
import { 
  WhatsAppOutlined, 
  DeleteOutlined, 
  FileTextOutlined,
  SmileOutlined,
  PhoneOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { updateNote, deleteNote } from '../apiService';
import QuickNotesSelector from './QuickNotesSelector';

const { TextArea } = Input;
const { Text } = Typography;

// Get status color helper function
const getStatusColor = (status) => {
  const statusColors = {
    'Unconfirmed': '#ffcccb',
    'Demo Booked': '#90EE90',
    'Demo Done': '#006400',
    'Demo Missed': '#FFA500',
    'Follow Up': '#FFFF00',
    'Details Shared': '#ADD8E6',
    'Offer Given': '#FFC0CB',
    'For Offer': '#8A2BE2',
    'NP1': '#00008B',
    'NP2': '#00008B',
    'NP3': '#00008B',
    'Closed': '#8B0000',
    'Future Follow': '#008B8B',
    'Admission': '#006400'
  };
  
  return statusColors[status] || '#f0f0f0';
};

// Mobile-optimized Notes Component
const RedesignedMobileNotes = ({ 
  leadId, 
  phone, 
  addNote, 
  notes = [], 
  refreshNotes, 
  leadName, 
  coordinatorName,
  statusHistory = []
}) => {
  // State management
  const [newNote, setNewNote] = useState('');
  const [editingNote, setEditingNote] = useState(null);
  const [editedContent, setEditedContent] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  
  // Refs
  const scrollRef = useRef(null);

  // Auto-scroll to bottom when new notes are added
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [notes]);

  // Handle submit note
  const handleSubmitNote = () => {
    if (!newNote.trim()) return;
    
    addNote(leadId, newNote);
    setNewNote('');
  };

  // Handle WhatsApp Business click - updated for mobile business app
  const handleWhatsAppClick = () => {
    if (!phone) {
      message.error('Phone number is required');
      return;
    }
    
    // Format phone number (remove any non-numeric characters)
    const formattedPhone = phone.replace(/\D/g, '');
    const text = encodeURIComponent(newNote);
    
    // Use the WhatsApp Business API URL specifically
    const whatsappBusinessUrl = `whatsappbusiness://send?phone=${formattedPhone}&text=${text}`;
    
    // Try to open WhatsApp Business app first
    window.location.href = whatsappBusinessUrl;
    
    // Fallback to regular WhatsApp app after a short delay if business app doesn't open
    setTimeout(() => {
      const whatsappUrl = `whatsapp://send?phone=${formattedPhone}&text=${text}`;
      window.location.href = whatsappUrl;
      
      // Finally fallback to web version if neither app opens
      setTimeout(() => {
        window.open(`https://api.whatsapp.com/send?phone=${formattedPhone}&text=${text}`, '_blank');
      }, 300);
    }, 300);
  };
  
  // Handle phone call - new function
  const handlePhoneCall = () => {
    if (!phone) {
      message.error('Phone number is required');
      return;
    }
    
    // Format phone number (remove any non-numeric characters)
    const formattedPhone = phone.replace(/\D/g, '');
    
    // Use tel: protocol to open the phone's dialer
    window.location.href = `tel:${formattedPhone}`;
  };
  
  // Handle note edit
  const handleStartEdit = (note) => {
    setEditingNote(note.id);
    setEditedContent(note.note_content);
  };
  
  const handleFinishEdit = async () => {
    if (!editingNote || !editedContent.trim()) {
      setEditingNote(null);
      return;
    }
    
    try {
      await updateNote(leadId, editingNote, editedContent);
      if (refreshNotes) refreshNotes();
      message.success('Note updated');
    } catch (error) {
      message.error('Failed to update note');
    } finally {
      setEditingNote(null);
      setEditedContent('');
    }
  };
  
  // Handle note delete
  const handleDeleteNote = async (noteId, e) => {
    e.stopPropagation();
    
    try {
      await deleteNote(leadId, noteId);
      if (refreshNotes) refreshNotes();
      message.success('Note deleted');
    } catch (error) {
      message.error('Failed to delete note');
    }
  };
  
  // Simple arrow component for status changes
  const ArrowRight = ({ className }) => (
    <svg 
      viewBox="0 0 24 24" 
      width="16" 
      height="16" 
      stroke="currentColor" 
      strokeWidth="2" 
      fill="none" 
      strokeLinecap="round" 
      strokeLinejoin="round" 
      className={className}
    >
      <path d="M5 12h14M12 5l7 7-7 7" />
    </svg>
  );
  
  // Render note bubble with swipe actions
  const renderNoteBubble = (note) => {
    if (editingNote === note.id) {
      return (
        <div className="p-3 bg-blue-50 rounded-lg mb-3">
          <TextArea
            value={editedContent}
            onChange={(e) => setEditedContent(e.target.value)}
            autoFocus
            autoSize={{ minRows: 2, maxRows: 5 }}
            className="mb-2"
          />
          <div className="flex justify-end space-x-2">
            <Button 
              size="small" 
              onClick={() => setEditingNote(null)}
            >
              Cancel
            </Button>
            <Button 
              size="small" 
              type="primary"
              onClick={handleFinishEdit}
            >
              Save
            </Button>
          </div>
        </div>
      );
    }
    
    const createdTime = moment(note.created_at);
    const timeDisplay = createdTime.isSame(moment(), 'day') 
      ? createdTime.format('h:mm A')
      : createdTime.format('MMM D, h:mm A');
      
    return (
      <div 
        className="bg-white rounded-lg shadow-sm mb-3 overflow-hidden"
        onClick={() => handleStartEdit(note)}
      >
        <div className="p-3 relative">
          <div className="flex justify-between items-center mb-1">
            <div className="flex items-center">
              <Avatar 
                size="small" 
                style={{ backgroundColor: '#1890ff' }}
                src={note.created_by_avatar}
              >
                {note.created_by_name ? note.created_by_name[0] : 'U'}
              </Avatar>
              <Text strong className="ml-2 text-sm">
                {note.created_by_name || 'Unknown'}
              </Text>
            </div>
            <Text type="secondary" className="text-xs">
              {timeDisplay}
            </Text>
          </div>
          
          <div className="text-gray-800 text-sm whitespace-pre-wrap">
            {note.note_content}
          </div>
          
          <Button
            type="text"
            size="small"
            icon={<DeleteOutlined />}
            className="absolute top-2 right-2 text-red-500 opacity-60"
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteNote(note.id, e);
            }}
          />
        </div>
      </div>
    );
  };
  
  // Render status change event
  const renderStatusChange = (item) => {
    const statusColor = getStatusColor(item.new_status);
    const textColor = ['Demo Done', 'Demo Missed', 'For Offer', 'NP1', 'NP2', 'NP3', 'Closed', 'Future Follow', 'Admission'].includes(item.new_status) 
      ? 'white' 
      : 'black';
    
    const changeDate = moment(item.changed_at);
    const formattedDate = changeDate.isSame(moment(), 'day')
      ? `Today at ${changeDate.format('h:mm A')}`
      : changeDate.format('MMM D, h:mm A');
    
    return (
      <div className="flex justify-center my-3">
        <div className="bg-gray-50 rounded-full px-3 py-1 max-w-xs text-center">
          <div className="text-xs text-gray-500">
            {item.changed_by || 'System'} changed status
          </div>
          <div className="flex justify-center items-center gap-2 my-1">
            {item.old_status && (
              <>
                <Tag color="#f0f0f0" className="text-xs">
                  {item.old_status}
                </Tag>
                <ArrowRight className="text-xs text-gray-400" />
              </>
            )}
            <Tag 
              color={statusColor} 
              className="text-xs"
              style={{ color: textColor }}
            >
              {item.new_status}
            </Tag>
          </div>
          <div className="text-xs text-gray-400">
            {formattedDate}
          </div>
        </div>
      </div>
    );
  };
  
  // Combine notes and status history
  const combinedItems = [
    ...notes.map(note => ({ ...note, type: 'note' })),
    ...statusHistory.map(status => ({ ...status, type: 'status' }))
  ].sort((a, b) => {
    const dateA = a.type === 'note' ? new Date(a.created_at) : new Date(a.changed_at);
    const dateB = b.type === 'note' ? new Date(b.created_at) : new Date(b.changed_at);
    return dateA - dateB;
  });

  return (
    <div className="h-full flex flex-col bg-gray-50">
      {/* Notes List */}
      <div 
        ref={scrollRef}
        className="flex-1 overflow-y-auto p-3 no-scrollbar" 
        style={{ 
          height: 'calc(100vh - 350px)'
        }}
      >
        {combinedItems.length === 0 ? (
          <div className="flex flex-col items-center justify-center h-full text-gray-400">
            <FileTextOutlined style={{ fontSize: 48 }} />
            <p className="mt-3">No notes or status changes yet</p>
          </div>
        ) : (
          combinedItems.map((item, index) => (
            <div key={`item-${item.type}-${index}`}>
              {item.type === 'note' 
                ? renderNoteBubble(item)
                : renderStatusChange(item, index)
              }
            </div>
          ))
        )}
      </div>
      
      {/* Input Area with QuickNotesSelector */}
      <div className="border-t bg-white p-3">
        <QuickNotesSelector 
          onNoteChange={(note) => setNewNote(note)}
          value={newNote}
        />
        
        <div className="flex mt-4 space-x-2">
          <Button
            type="primary"
            icon={<WhatsAppOutlined />}
            onClick={handleWhatsAppClick}
            style={{ flex: 1, backgroundColor: '#25D366', borderColor: '#25D366' }}
          >
            WhatsApp
          </Button>
          <Button
            type="primary"
            onClick={handleSubmitNote}
            style={{ flex: 1 }}
            disabled={!newNote.trim()}
          >
            Add Note
          </Button>
          <Button
            type="primary"
            icon={<PhoneOutlined />}
            onClick={handlePhoneCall}
            style={{ flex: 1 }}
          >
            Call
          </Button>
        </div>
      </div>
    </div>
  );
};

RedesignedMobileNotes.propTypes = {
  leadId: PropTypes.number.isRequired,
  phone: PropTypes.string.isRequired,
  addNote: PropTypes.func.isRequired,
  notes: PropTypes.array,
  refreshNotes: PropTypes.func,
  leadName: PropTypes.string.isRequired,
  coordinatorName: PropTypes.string,
  statusHistory: PropTypes.array
};

export default RedesignedMobileNotes;