import axios from 'axios';
import { 
  formatDateForAPI, 
  formatTimeForAPI, 
  formatDateTimeForAPI, 
  formatDateTimeForDisplay,
  parseDateFromAPI,
  parseTimeFromAPI,
  parseDateTimeFromAPI
} from './utils/dateUtils';
import moment from 'moment';

const API_BASE_URL = 'https://it.nilconnect.in/wp-json/ei-crm/v1';
const AUTH_URL = 'https://it.nilconnect.in/wp-json/jwt-auth/v1/token';
const USER_DETAILS_URL = 'https://it.nilconnect.in/wp-json/ei-crm/v1/users/me/details';

const apiService = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

apiService.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('jwtToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

apiService.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      localStorage.removeItem('jwtToken');
      
      try {
        const refreshToken = localStorage.getItem('refreshToken');
        if (refreshToken) {
          const response = await axios.post(`${AUTH_URL}/refresh`, { refresh_token: refreshToken });
          const newToken = response.data.token;
          localStorage.setItem('jwtToken', newToken);
          apiService.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
          originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
          return apiService(originalRequest);
        } else {
          console.error('No refresh token available');
          return Promise.reject(error);
        }
      } catch (refreshError) {
        console.error('Error refreshing token:', refreshError);
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);



export const authenticate = async (username, password) => {
  try {
    const response = await axios.post(AUTH_URL, { username, password });
    const token = response.data.token;
    if (!token) {
      throw new Error('Invalid response from server: missing token');
    }
    localStorage.setItem('jwtToken', token);
    apiService.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return token;
  } catch (error) {
    console.error('Authentication error details:', error.response || error);
    throw new Error(error.response?.data?.message || 'Authentication failed. Please try again.');
  }
};

// Add these new methods for notes
export const updateNote = async (leadId, noteId, noteContent) => {
  try {
    const response = await apiService.put(`/leads/${leadId}/notes/${noteId}`, {
      note_content: noteContent
    });
    return response.data;
  } catch (error) {
    console.error('Error updating note:', error);
    throw error;
  }
};

export const deleteNote = async (leadId, noteId) => {
  try {
    const response = await apiService.delete(`/leads/${leadId}/notes/${noteId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting note:', error);
    throw error;
  }
};

export const getCurrentUserDetails = async () => {
  try {
    const response = await apiService.get(USER_DETAILS_URL);
    return response.data;
  } catch (error) {
    console.error('Error fetching current user details:', error);
    throw new Error('Failed to fetch user details');
  }
};

export const login = async (username, password) => {
  try {
    await authenticate(username, password);
    const userDetails = await getCurrentUserDetails();
    return userDetails;
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};

export const getUserDetailsById = async (userId) => {
  try {
    const response = await apiService.get(`/users/${userId}/details`);
    return response.data;
  } catch (error) {
    console.error('Error fetching user details:', error);
    throw error;
  }
};

export const logout = () => {
  localStorage.removeItem('jwtToken');
  delete apiService.defaults.headers.common['Authorization'];
};

export const isLoggedIn = () => {
  return !!localStorage.getItem('jwtToken');
};

export const getDashboardData = async () => {
  try {
    const response = await apiService.get('/dashboard');
    return response.data;
  } catch (error) {
    console.error('Error fetching dashboard data:', error);
    throw error;
  }
};

export const getAllUsers = async () => {
  try {
    const response = await apiService.get('/users');
    return response.data;
  } catch (error) {
    console.error('Error fetching all users:', error);
    throw error;
  }
};

export const getNotifications = async () => {
  try {
    const response = await apiService.get('/notifications');
    return response.data;
  } catch (error) {
    console.error('Error fetching notifications:', error);
    return { error: 'Failed to fetch notifications', data: [] };
  }
};

export const getUnreadNotificationsCount = async () => {
  try {
    const response = await apiService.get('/notifications/unread-count');
    return response.data;
  } catch (error) {
    console.error('Error fetching unread notifications count:', error);
    throw error;
  }
};

export const getTaskById = async (id) => {
  try {
    const response = await apiService.get(`/tasks/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching task:', error);
    throw error;
  }
};

export const getNotificationDetails = async (id) => {
  try {
    const response = await apiService.get(`/notifications/${id}/details`);
    return response.data;
  } catch (error) {
    console.error('Error fetching notification details:', error);
    throw error;
  }
};

export const markNotificationAsRead = async (id) => {
  try {
    const response = await apiService.post(`/notifications/${id}/read`);
    return response.data;
  } catch (error) {
    console.error('Error marking notification as read:', error);
    throw error;
  }
};

export const getUpcomingReminders = async () => {
  try {
    const response = await apiService.get('/reminders/upcoming');
    return response.data;
  } catch (error) {
    console.error('Error fetching upcoming reminders:', error);
    throw error;
  }
};

export const getCoordinators = async (params = {}) => {
  try {
    const response = await apiService.get('/coordinators', { params });
    return response.data.coordinators;
  } catch (error) {
    console.error('Error fetching coordinators:', error);
    throw error;
  }
};

export const sendChatMessage1 = async (message, mediaId = null, parentId = null, mentions = []) => {
  try {
    const response = await apiService.post('/chat/messages', {
      message,
      media_id: mediaId,
      parent_id: parentId,
      mentions,
    });
    return response.data;
  } catch (error) {
    console.error('Error sending chat message:', error);
    throw error;
  }
};



export const getLeads = async (filters) => {
  try {
    const params = new URLSearchParams();

    // Loop through the filters to build the URL parameters
    Object.entries(filters).forEach(([key, value]) => {
      if (key !== 'coordinator_id' && key !== 'status') {
        if (value !== null && value !== undefined && value !== '') {
          params.append(key, value);
        }
      }
    });

    // Handle status array separately
    if (filters.status && Array.isArray(filters.status)) {
      filters.status.forEach(status => params.append('status[]', status));
    }

    // Handle coordinator_id separately
    let url = `/leads?${params.toString()}`;
    if (filters.coordinator_id === '') {
      url += `&coordinator_id=`;
    } else if (filters.coordinator_id === 'null') {
      url += `&coordinator_id=null`;
    } else if (filters.coordinator_id) {
      url += `&coordinator_id=${filters.coordinator_id}`;
    }

    // Add no_activity parameter if present
    if (filters.no_activity) {
      url += '&no_activity=true';
    }

    if (filters.stale_leads) {
      url += '&stale_leads=true';
    }

    const response = await apiService.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching leads:', error);
    throw error;
  }
};

// Add these methods to apiService.js

export const getAutoReassignRules = async () => {
  try {
    const response = await apiService.get('/auto-reassign/rules');
    return response.data;
  } catch (error) {
    console.error('Error fetching auto reassignment rules:', error);
    throw error;
  }
};

export const createAutoReassignRule = async (ruleData) => {
  try {
    const response = await apiService.post('/auto-reassign/rules', ruleData);
    return response.data;
  } catch (error) {
    console.error('Error creating auto reassignment rule:', error);
    throw error;
  }
};

export const updateAutoReassignRule = async (ruleId, ruleData) => {
  try {
    const response = await apiService.put(`/auto-reassign/rules/${ruleId}`, ruleData);
    return response.data;
  } catch (error) {
    console.error('Error updating auto reassignment rule:', error);
    throw error;
  }
};

export const deleteAutoReassignRule = async (ruleId) => {
  try {
    const response = await apiService.delete(`/auto-reassign/rules/${ruleId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting auto reassignment rule:', error);
    throw error;
  }
};

export const toggleAutoReassignRule = async (ruleId, active) => {
  try {
    const response = await apiService.post(`/auto-reassign/rules/${ruleId}/toggle`, { active });
    return response.data;
  } catch (error) {
    console.error('Error toggling auto reassignment rule:', error);
    throw error;
  }
};



export const getLead = async (id) => {
  try {
    const response = await apiService.get(`/leads/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching lead:', error);
    throw error;
  }
};

export const createLead = async (data) => {
  try {
    const formattedData = {
      ...data,
      booking_date: data.booking_date ? formatDateForAPI(data.booking_date) : null,
      booking_time: data.booking_time ? formatTimeForAPI(data.booking_time) : null,
      call_date: data.call_date ? formatDateTimeForAPI(data.call_date) : null,
      next_call_date: data.next_call_date ? formatDateTimeForAPI(data.next_call_date) : null,
      fee_due_date: data.fee_due_date ? formatDateForAPI(data.fee_due_date) : null,
      fee_due: data.fee_due ? parseFloat(data.fee_due) : null,
    };
    const response = await apiService.post('/leads', formattedData);
    return response.data;
  } catch (error) {
    console.error('Error creating lead:', error);
    throw error;
  }
};


export const updateLead = async (id, data) => {
  try {
    const formattedData = { ...data };

    if (data.booking_date) {
      formattedData.booking_date = formatDateForAPI(data.booking_date);
    }
    if (data.booking_time) {
      formattedData.booking_time = moment(data.booking_time, 'hh:mm A').format('HH:mm:ss');
    }
    if (data.call_date) {
      formattedData.call_date = formatDateTimeForAPI(data.call_date);
    }
    if (data.next_call_date) {
      formattedData.next_call_date = formatDateTimeForAPI(data.next_call_date);
    }
    if (data.fee_due_date) {
      formattedData.fee_due_date = formatDateForAPI(data.fee_due_date);
    }
    if (data.fee_due) {
      formattedData.fee_due = parseFloat(data.fee_due);
    }

    const response = await apiService.put(`/leads/${id}`, formattedData);
    return response.data;
  } catch (error) {
    console.error('Error updating lead:', error);
    throw error;
  }
};

export const deleteLead = async (id) => {
  try {
    const response = await apiService.delete(`/leads/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting lead:', error);
    throw error;
  }
};

export const bulkAssignLeads = async (data) => {
  try {
    const response = await apiService.post('/leads/bulk-assign', data);
    return response.data;
  } catch (error) {
    console.error('Error bulk assigning leads:', error);
    throw error;
  }
};

export const getTasks = async (filters) => {
  try {
    const response = await apiService.get('/tasks', { params: filters });
    return Array.isArray(response.data) ? response.data : (response.data?.tasks ?? []);
  } catch (error) {
    console.error('Error in getTasks:', error);
    throw error;
  }
};

export const createTask = async (taskData) => {
  try {
    const formattedData = {
      ...taskData,
      due_date: taskData.due_date ? formatDateTimeForAPI(taskData.due_date) : null,
    };
    const response = await apiService.post('/tasks', formattedData);
    return response.data;
  } catch (error) {
    console.error('Error in createTask:', error);
    throw error;
  }
};

export const updateTask = async (id, data) => {
  try {
    const formattedData = {
      ...data,
      due_date: data.due_date ? formatDateTimeForAPI(data.due_date) : null,
    };
    const response = await apiService.put(`/tasks/${id}`, formattedData);
    return response.data;
  } catch (error) {
    console.error('Error updating task:', error);
    throw error;
  }
};

export const deleteTask = async (id) => {
  try {
    const response = await apiService.delete(`/tasks/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting task:', error);
    throw error;
  }
};

export const getNotes = async (leadId) => {
  try {
    const response = await apiService.get(`/leads/${leadId}/notes`);
    return response.data;
  } catch (error) {
    console.error('Error in getNotes:', error);
    throw error;
  }
};

export const addNote = async (leadId, noteContent) => {
  try {
    const response = await apiService.post(`/leads/${leadId}/notes`, { note_content: noteContent });
    return response.data;
  } catch (error) {
    console.error('Error in addNote:', error);
    throw error;
  }
};

export const getCannedResponses = async (params) => {
  try {
    const response = await apiService.get('/canned-responses', { params });
    return response.data;
  } catch (error) {
    console.error('Error in getCannedResponses:', error);
    throw error;
  }
};

export const getCannedResponse = async (id) => {
  try {
    const response = await apiService.get(`/canned-responses/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching canned response:', error);
    throw error;
  }
};

export const createCannedResponse = async (data) => {
  try {
    const response = await apiService.post('/canned-responses', data);
    return response.data;
  } catch (error) {
    console.error('Error creating canned response:', error);
    throw error;
  }
};

export const updateCannedResponse = async (id, data) => {
  try {
    const response = await apiService.put(`/canned-responses/${id}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating canned response:', error);
    throw error;
  }
};

export const deleteCannedResponse = async (id) => {
  try {
    const response = await apiService.delete(`/canned-responses/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting canned response:', error);
    throw error;
  }
};

export const getReminders = async () => {
  try {
    const response = await apiService.get('/reminders');
    return response.data;
  } catch (error) {
    console.error('Error fetching reminders:', error);
    throw error;
  }
};

export const getReminder = async (reminderId) => {
  try {
    const response = await apiService.get(`/reminders/${reminderId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching reminder:', error);
    throw error;
  }
};

export const createReminder = async (data) => {
  try {
    const formattedData = {
      ...data,
      datetime: data.datetime ? formatDateTimeForAPI(data.datetime) : null,
    };
    const response = await apiService.post('/reminders', formattedData);
    return response.data;
  } catch (error) {
    console.error('Error creating reminder:', error);
    throw error;
  }
};

export const updateReminder = async (id, data) => {
  try {
    const formattedData = {
      ...data,
      datetime: data.datetime ? formatDateTimeForAPI(data.datetime) : null,
    };
    const response = await apiService.put(`/reminders/${id}`, formattedData);
    return response.data;
  } catch (error) {
    console.error('Error updating reminder:', error);
    throw error;
  }
};

export const deleteReminder = async (id) => {
  try {
    const response = await apiService.delete(`/reminders/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting reminder:', error);
    throw error;
  }
};

export const getLeadStatusHistory = async (leadId) => {
  try {
    const response = await apiService.get(`/leads/${leadId}/status-history`);
    return response.data;
  } catch (error) {
    console.error('Error fetching lead status history:', error);
    throw error;
  }
};

export const getUserNotes = async () => {
  try {
    const response = await apiService.get('/user-notes');
    return response.data;
  } catch (error) {
    console.error('Error fetching user notes:', error);
    throw error;
  }
};

export const createUserNote = async (data) => {
  try {
    const response = await apiService.post('/user-notes', data);
    return response.data;
  } catch (error) {
    console.error('Error creating user note:', error);
    throw error;
  }
};

export const updateUserNote = async (id, data) => {
  try {
    const response = await apiService.put(`/user-notes/${id}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating user note:', error);
    throw error;
  }
};

export const deleteUserNote = async (id) => {
  try {
    const response = await apiService.delete(`/user-notes/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting user note:', error);
    throw error;
  }
};

export const getWikiEntries = async () => {
  try {
    const response = await apiService.get('/wiki');
    return response.data;
  } catch (error) {
    console.error('Error fetching wiki entries:', error);
    throw error;
  }
};

export const createWikiEntry = async (data) => {
  try {
    const response = await apiService.post('/wiki', data);
    return response.data;
  } catch (error) {
    console.error('Error creating wiki entry:', error);
    throw error;
  }
};

export const updateWikiEntry = async (id, data) => {
  try {
    const response = await apiService.put(`/wiki/${id}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating wiki entry:', error);
    throw error;
  }
};

export const deleteWikiEntry = async (id) => {
  try {
    const response = await apiService.delete(`/wiki/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting wiki entry:', error);
    throw error;
  }
};

export const getSocialUpdates = async () => {
  try {
    const response = await apiService.get('/social-updates', {
      params: { include_comments: true, comments_per_update: 5 }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching social updates:', error);
    throw error;
  }
};

export const createSocialUpdate = async (updateData, onUploadProgress) => {
  try {
    const response = await apiService.post('/social-updates', updateData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    });
    return response.data;
  } catch (error) {
    console.error('Error creating social update:', error);
    throw error;
  }
};

export const getChatMessages = async (page = 1, perPage = 50, search = '', parentId = null) => {
  try {
    const response = await apiService.get('/chat/messages', {
      params: { page, per_page: perPage, search, parent_id: parentId }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching chat messages:', error);
    throw error;
  }
};

export const editChatMessage = async (messageId, newContent) => {
  try {
    const response = await apiService.put(`/global-chat/${messageId}`, { content: newContent });
    return response.data;
  } catch (error) {
    console.error('Error editing chat message:', error);
    throw error;
  }
};

export const deleteChatMessage = async (messageId) => {
  try {
    const response = await apiService.delete(`/chat/messages/${messageId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting chat message:', error);
    throw error;
  }
};


export const likeSocialUpdate = async (id) => {
  try {
    const response = await apiService.post(`/social-updates/${id}/like`);
    return response.data;
  } catch (error) {
    console.error('Error liking social update:', error);
    throw error;
  }
};

export const unlikeSocialUpdate = async (id) => {
  try {
    const response = await apiService.delete(`/social-updates/${id}/like`);
    return response.data;
  } catch (error) {
    console.error('Error unliking social update:', error);
    throw error;
  }
};

export const getUserProfile = async () => {
  try {
    const response = await apiService.get('/user-profile');
    return response.data;
  } catch (error) {
    console.error('Error fetching user profile:', error);
    throw error;
  }
};

export const updateUserProfile = async (userId, formData) => {
  try {
    const token = localStorage.getItem('jwtToken');
    const response = await axios.post(`${API_BASE_URL}/users/me/details`, formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating user profile:', error);
    throw error;
  }
};

export const pinSocialUpdate = async (id) => {
  try {
    const response = await apiService.post(`/social-updates/${id}/pin`);
    return response.data;
  } catch (error) {
    console.error('Error pinning social update:', error);
    throw error;
  }
};

export const unpinSocialUpdate = async (id) => {
  try {
    const response = await apiService.post(`/social-updates/${id}/unpin`);
    return response.data;
  } catch (error) {
    console.error('Error unpinning social update:', error);
    throw error;
  }
};

export const getComments = async (updateId, limit = 3) => {
  try {
    const response = await apiService.get(`/social-updates/${updateId}/comments`, {
      params: { limit }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching comments:', error);
    throw error;
  }
};

export const addComment = async (updateId, data) => {
  try {
    const response = await apiService.post(`/social-updates/${updateId}/comments`, data);
    return response.data;
  } catch (error) {
    console.error('Error adding comment:', error);
    throw error;
  }
};

export const getGlobalChatMessages = async (page = 1, perPage = 50, parentId = null) => {
  try {
    const response = await apiService.get('/global-chat', {
      params: { page, per_page: perPage, parent_id: parentId }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching global chat messages:', error);
    throw error;
  }
};;

export const sendChatMessage = async (message, mediaId = null, parentId = null, mentions = []) => {
  try {
    const response = await apiService.post('/chat/messages', {
      message,
      media_id: mediaId,
      parent_id: parentId,
      mentions,
    });
    return response.data;
  } catch (error) {
    console.error('Error sending chat message:', error);
    throw error;
  }
};

export const getSingleChatMessage = async (messageId) => {
  try {
    const response = await apiService.get(`/chat/messages/${messageId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching single chat message:', error);
    throw error;
  }
};

export const updateChatMessage = async (messageId, newContent) => {
  try {
    const response = await apiService.put(`/chat/messages/${messageId}`, { message: newContent });
    return response.data;
  } catch (error) {
    console.error('Error updating chat message:', error);
    throw error;
  }
};

export const markMessagesAsRead = async (messageIds) => {
  try {
    const response = await apiService.post('/chat/messages/read', { message_ids: messageIds });
    return response.data;
  } catch (error) {
    console.error('Error marking messages as read:', error);
    throw error;
  }
};

export const markGlobalChatMessagesAsRead = async (messageIds) => {
  try {
    const response = await apiService.post('/global-chat/mark-read', { message_ids: messageIds });
    return response.data;
  } catch (error) {
    console.error('Error marking global chat messages as read:', error);
    throw error;
  }
};
export const getOnlineUsers = async () => {
  try {
    const response = await apiService.get('/chat/online-users');
    return response.data;
  } catch (error) {
    console.error('Error fetching online users:', error);
    throw error;
  }
};
export const uploadMedia = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await apiService.post('/media-upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading media:', error);
    throw error;
  }
};

export const uploadMedia1 = async (file) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const response = await axios.post('/wp-json/ei-crm/v1/chat/upload-media', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading media:', error);
    throw error;
  }
};
export const uploadMediaToWordPress = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await axios.post('/wp-json/wp/v2/media', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
      },
    });
    
    return {
      id: response.data.id,
      url: response.data.source_url,
      name: response.data.title.rendered,
      type: response.data.mime_type
    };
  } catch (error) {
    console.error('Error uploading media:', error);
    throw error;
  }
};

export const convertToIST = (utcTimestamp) => {
  return moment.utc(utcTimestamp).tz('Asia/Kolkata').format('YYYY-MM-DD HH:mm:ss');
};

export const getUserDetails = async () => {
  try {
    const token = localStorage.getItem('jwtToken');
    const response = await axios.get(`${API_BASE_URL}/users/me/details`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching user details:', error);
    throw error;
  }
};

export const getUpcomingReminderNotifications = async () => {
  try {
    const response = await apiService.get('/reminders/upcoming-notifications');
    return response.data;
  } catch (error) {
    console.error('Error fetching upcoming reminder notifications:', error);
    throw error;
  }
};

export const deleteSocialUpdate = async (id) => {
  try {
    const response = await apiService.delete(`/social-updates/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting social update:', error);
    throw error;
  }
};

export const getWikiStructure = async () => {
  try {
    const response = await apiService.get('/wiki/structure');
    return response.data;
  } catch (error) {
    console.error('Error fetching wiki structure:', error);
    throw error;
  }
};

export const getUnreadMessageCount = async () => {
  try {
    const response = await apiService.get('/global-chat/unread-count');
    return response.data.count;
  } catch (error) {
    console.error('Error fetching unread message count:', error);
    throw error;
  }
};

export const createWikiMainSection = async (data) => {
  try {
    const response = await apiService.post('/wiki/main-section', data);
    return response.data;
  } catch (error) {
    console.error('Error creating main section:', error);
    throw error;
  }
};

export const updateWikiMainSection = async (id, data) => {
  try {
    const response = await apiService.put(`/wiki/main-section/${id}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating main section:', error);
    throw error;
  }
};

export const deleteWikiMainSection = async (id) => {
  try {
    const response = await apiService.delete(`/wiki/main-section/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting main section:', error);
    throw error;
  }
};

export const createWikiSubsection = async (data) => {
  try {
    const response = await apiService.post('/wiki/subsection', data);
    return response.data;
  } catch (error) {
    console.error('Error creating subsection:', error);
    throw error;
  }
};

export const updateWikiSubsection = async (id, data) => {
  try {
    const response = await apiService.put(`/wiki/subsection/${id}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating subsection:', error);
    throw error;
  }
};

export const deleteWikiSubsection = async (id) => {
  try {
    const response = await apiService.delete(`/wiki/subsection/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting subsection:', error);
    throw error;
  }
};

export const createWikiTopic = async (data) => {
  try {
    const response = await apiService.post('/wiki/topic', data);
    return response.data;
  } catch (error) {
    console.error('Error creating topic:', error);
    throw error;
  }
};

export const getWikiTopicContent = async (id) => {
  try {
    const response = await apiService.get(`/wiki/topic?id=${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching topic content:', error);
    throw error;
  }
};

export const updateWikiTopic = async (data) => {
  try {
    const response = await apiService.put('/wiki/topic', data);
    return response.data;
  } catch (error) {
    console.error('Error updating topic:', error);
    throw error;
  }
};

export const deleteWikiTopic = async (id) => {
  try {
    const response = await apiService.delete(`/wiki/topic?id=${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting topic:', error);
    throw error;
  }
};

export const getGlobalChatOnlineUsers = async () => {
  try {
    const response = await apiService.get('/global-chat/online-users');
    return response.data;
  } catch (error) {
    console.error('Error fetching online users:', error);
    throw error;
  }
};

export const updateOnlineStatus = async () => {
  try {
    const response = await apiService.post('/chat/update-status');
    return response.data;
  } catch (error) {
    console.error('Error updating online status:', error);
    throw error;
  }
};



export const searchWiki = async (query) => {
  try {
    const response = await apiService.get(`/wiki/search?query=${encodeURIComponent(query)}`);
    return response.data;
  } catch (error) {
    console.error('Error searching wiki:', error);
    throw error;
  }
};

export const reorderWikiItems = async (table, items) => {
  try {
    const response = await apiService.post('/wiki/reorder', { table, items });
    return response.data;
  } catch (error) {
    console.error('Error reordering wiki items:', error);
    throw error;
  }
};

export const getWikiActivityLog = async (page = 1, perPage = 50) => {
  try {
    const response = await apiService.get(`/wiki/activity-log?page=${page}&per_page=${perPage}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching wiki activity log:', error);
    throw error;
    }
};

// In apiService.js

export const sendWebhookData = async (data) => {
  try {
    const response = await axios.post("https://dj.nilconnect.in/api/external/external-leads/webhook/", data);
    return response.data;
  } catch (error) {
    console.error('Error sending webhook data:', error);
    throw error;
  }
};

export const getCoordinatorMonthlyStats = async (coordinatorId, month, year) => {
  try {
    const response = await apiService.get(`/coordinators/${coordinatorId}/monthly-stats`, {
      params: {
        month,
        year
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching coordinator stats:', error);
    throw error;
  }
};

// Add these new methods to apiService.js

export const getCoordinatorDetailedStats = async (coordinatorId) => {
  try {
    const response = await apiService.get(`/coordinators/${coordinatorId}/detailed-stats`);
    return response.data;
  } catch (error) {
    console.error('Error fetching coordinator detailed stats:', error);
    throw error;
  }
};

export const getCoordinatorsOverviewStats = async () => {
  try {
    const response = await apiService.get('/coordinators/overview-stats');
    return response.data;
  } catch (error) {
    console.error('Error fetching coordinators overview stats:', error);
    throw error;
  }
};

export const getLeadAttachments = async (leadId) => {
  try {
    const response = await apiService.get(`/leads/${leadId}/attachments`);
    return response.data;
  } catch (error) {
    console.error('Error fetching lead attachments:', error);
    throw error;
  }
};

// Upload lead attachment
export const uploadLeadAttachment = async (leadId, formData) => {
  try {
    const response = await apiService.post(`/leads/${leadId}/attachments`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading lead attachment:', error);
    throw error;
  }
};

export const deleteLeadAttachment = async (leadId, attachmentId) => {
  try {
    const response = await apiService.delete(`/leads/${leadId}/attachments/${attachmentId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting lead attachment:', error);
    throw error;
  }
};

export const getCoordinatorCycles = async () => {
  try {
    const response = await apiService.get('/coordinator-cycles');
    return response.data;
  } catch (error) {
    console.error('Error fetching coordinator cycles:', error);
    throw error;
  }
};

export const createCoordinatorCycle = async (cycleData) => {
  try {
    const response = await apiService.post('/coordinator-cycles', cycleData);
    return response.data;
  } catch (error) {
    console.error('Error creating coordinator cycle:', error);
    throw error;
  }
};

export const updateCoordinatorCycle = async (cycleId, cycleData) => {
  try {
    const response = await apiService.put(`/coordinator-cycles/${cycleId}`, cycleData);
    return response.data;
  } catch (error) {
    console.error('Error updating coordinator cycle:', error);
    throw error;
  }
};

export const deleteCoordinatorCycle = async (cycleId) => {
  try {
    const response = await apiService.delete(`/coordinator-cycles/${cycleId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting coordinator cycle:', error);
    throw error;
  }
};

export const getCoordinatorCycleDetails = async (cycleId) => {
  try {
    const response = await apiService.get(`/coordinator-cycles/${cycleId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching cycle details:', error);
    throw error;
  }
};

const CHAT_BASE_URL = 'https://nationalinstituteoflanguage.in/wp-json/rtac/v1';

export const getCustomerChats = async () => {
  try {
    const response = await apiService.get(`${CHAT_BASE_URL}/chats`);
    return response.data;
  } catch (error) {
    console.error('Error fetching customer chats:', error);
    throw error;
  }
};

export const getCustomerChatMessages = async (sessionId) => {
  try {
    const response = await apiService.get(`${CHAT_BASE_URL}/messages/${sessionId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching chat messages:', error);
    throw error;
  }
};

export const sendCustomerChatMessage = async (sessionId, message) => {
  try {
    const response = await apiService.post(`${CHAT_BASE_URL}/messages`, {
      session_id: sessionId,
      message: message,
      sender: 'agent',
      page_url: window.location.href
    });
    return response.data;
  } catch (error) {
    console.error('Error sending chat message:', error);
    throw error;
  }
};

export const markCustomerChatMessagesAsRead = async (sessionId) => {
  try {
    const response = await apiService.post(`${CHAT_BASE_URL}/messages/${sessionId}/read`);
    return response.data;
  } catch (error) {
    console.error('Error marking messages as read:', error);
    throw error;
  }
};

// WebSocket connection for real-time chat
export const connectCustomerChatWebSocket = (sessionId, onMessage) => {
  const ws = new WebSocket('wss://nationalinstituteoflanguage.in/ws/');
  
  ws.onopen = () => {
    console.log('Connected to customer chat WebSocket');
    ws.send(JSON.stringify({
      type: 'identify',
      role: 'agent',
      session_id: sessionId
    }));
  };

  ws.onmessage = (event) => {
    try {
      const data = JSON.parse(event.data);
      onMessage(data);
    } catch (error) {
      console.error('Error parsing WebSocket message:', error);
    }
  };

  ws.onerror = (error) => {
    console.error('WebSocket error:', error);
  };

  ws.onclose = () => {
    console.log('WebSocket connection closed');
    // Implement reconnection with exponential backoff
    setTimeout(() => connectCustomerChatWebSocket(sessionId, onMessage), 5000);
  };

  return ws;
};

// Get unread chat count
export const getUnreadCustomerChatCount = async () => {
  try {
    const response = await apiService.get(`${CHAT_BASE_URL}/chats/unread-count`);
    return response.data.count;
  } catch (error) {
    console.error('Error fetching unread chat count:', error);
    throw error;
  }
};

// Get chat history
export const getCustomerChatHistory = async (sessionId) => {
  try {
    const response = await apiService.get(`${CHAT_BASE_URL}/messages/${sessionId}/history`);
    return response.data;
  } catch (error) {
    console.error('Error fetching chat history:', error);
    throw error;
  }
};

// Update agent status
export const updateAgentStatus = async (status) => {
  try {
    const response = await apiService.post(`${CHAT_BASE_URL}/agent/status`, { status });
    return response.data;
  } catch (error) {
    console.error('Error updating agent status:', error);
    throw error;
  }

};

// Add these methods to apiService.js

export const getAssignmentRules = async () => {
  try {
    const response = await apiService.get('/assignment-rules');
    return response.data;
  } catch (error) {
    console.error('Error fetching assignment rules:', error);
    throw error;
  }
};

export const createAssignmentRule = async (ruleData) => {
  try {
    const response = await apiService.post('/assignment-rules', ruleData);
    return response.data;
  } catch (error) {
    console.error('Error creating assignment rule:', error);
    throw error;
  }
};

export const updateAssignmentRule = async (ruleId, ruleData) => {
  try {
    const response = await apiService.put(`/assignment-rules/${ruleId}`, ruleData);
    return response.data;
  } catch (error) {
    console.error('Error updating assignment rule:', error);
    throw error;
  }
};

export const deleteAssignmentRule = async (ruleId) => {
  try {
    const response = await apiService.delete(`/assignment-rules/${ruleId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting assignment rule:', error);
    throw error;
  }
};

export const resetAssignmentCounts = async () => {
  try {
    const response = await apiService.post('/assignment-rules/reset-counts');
    return response.data;
  } catch (error) {
    console.error('Error resetting assignment counts:', error);
    throw error;
  }
};

// Add these methods to apiService.js

export const getPrivateMessages = async (conversationId, page = 1, perPage = 50) => {
  try {
    const response = await apiService.get('/private-chat/messages', {
      params: { conversation_id: conversationId, page, per_page: perPage }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching private messages:', error);
    throw error;
  }
};

export const sendPrivateMessage = async (receiverId, message, file = null) => {
  try {
    let formData = new FormData();
    formData.append('receiver_id', receiverId);
    formData.append('message', message);
    
    if (file) {
      formData.append('file', file);
    }
    
    const response = await apiService.post('/private-chat/messages', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error sending private message:', error);
    throw error;
  }
};

export const getPrivateConversations = async (page = 1, perPage = 20) => {
  try {
    const response = await apiService.get('/private-chat/conversations', {
      params: { page, per_page: perPage }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching private conversations:', error);
    throw error;
  }
};

export const markPrivateMessagesAsRead = async (conversationId) => {
  try {
    const response = await apiService.post('/private-chat/messages/mark-read', {
      conversation_id: conversationId
    });
    return response.data;
  } catch (error) {
    console.error('Error marking private messages as read:', error);
    throw error;
  }
};

export const getPrivateUnreadCount = async () => {
  try {
    const response = await apiService.get('/private-chat/messages/unread-count');
    return response.data.unread_count;
  } catch (error) {
    console.error('Error fetching private unread count:', error);
    throw error;
  }
};

export const getNextCallLeads = async () => {
  try {
    const response = await apiService.get('/coordinators/next-call-leads');
    return response.data;
  } catch (error) {
    console.error('Error fetching next call leads:', error);
    throw error;
  }
};

export const updateGlobalChatOnlineStatus = async (status) => {
  try {
    const response = await apiService.post('/global-chat/online-status', { status });
    return response.data;
  } catch (error) {
    console.error('Error updating online status:', error);
    throw error;
  }
};;

export default apiService;
