import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import CircularProgress from '@mui/material/CircularProgress';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Leads from './components/Leads'; // Keep the original Leads component
import MobileLeadsView from './components/MobileLeadsView'; // Import mobile view
import TaskList from './components/TaskList';
import CannedResponses from './components/CannedResponses';
import Reminders from './components/Reminders';
import Navbar from './components/Navbar';
import UserNotes from './components/UserNotes';
import Wiki from './components/Wiki';
import SocialUpdates from './components/SocialUpdates';
import UserProfile from './components/UserProfile';
import GlobalChat from './components/GlobalChat';
import ReminderPopup from './components/ReminderPopup';
import Reports from './components/Reports';
import QuotePopup from './components/QuotePopup';
import CoordinatorCycles from './components/CoordinatorCycles';
import AssignmentRules from './components/AssignmentRules';
import CyclesManagement from './components/CyclesManagement';
import OnlineUsersBar from './components/OnlineUsersBar';
import { DarkModeProvider } from './components/DarkModeContext';

import apiService, { logout, getCurrentUserDetails } from './apiService';

// Create themes
const lightTheme = createTheme();
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#1f1f1f',
      paper: '#262626',
    },
    text: {
      primary: '#ffffff',
      secondary: '#a0a0a0',
    },
  },
});

// Mobile detection hook
const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);
  
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);
  
  return isMobile;
};

// Wrapper component for Dashboard with dark theme
const DarkDashboard = () => (
  <ThemeProvider theme={darkTheme}>
    <div style={{ 
      backgroundColor: '#1f1f1f',
      minHeight: '100vh',
      position: 'fixed',
      top: 100,
      left: 0,
      right: 0,
      bottom: 0,
      overflow: 'auto'
    }}>
      <Dashboard />
    </div>
  </ThemeProvider>
);

// Responsive Leads Component that selects between desktop or mobile view
const ResponsiveLeads = ({ currentUser }) => {
  const isMobile = useIsMobile();
  
  return isMobile ? (
    <MobileLeadsView currentUser={currentUser} />
  ) : (
    <Leads currentUser={currentUser} />
  );
};

const AppContent = ({ authState, setAuthState, handleLogin, handleLogout }) => {
  const [notesVisible, setNotesVisible] = useState(false);
  const [showQuote, setShowQuote] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  useEffect(() => {
    if (location.pathname === '/social' && authState.isAuthenticated) {
      setShowQuote(Date.now());
    }
  }, [location, authState.isAuthenticated]);

  useEffect(() => {
    const interceptor = apiService.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          handleLogout();
          navigate('/login');
        }
        return Promise.reject(error);
      }
    );

    return () => {
      apiService.interceptors.response.eject(interceptor);
    };
  }, [handleLogout, navigate]);

  const toggleNotes = useCallback(() => {
    setNotesVisible(prev => !prev);
  }, []);

  const handleCloseQuote = () => {
    setShowQuote(false);
  };

  return (
    <div className="App">
      {authState.isAuthenticated && authState.currentUser && !isMobile && (
        <>
          <Navbar 
            logout={handleLogout} 
            currentUser={authState.currentUser}
            fullyAuthenticated={authState.fullyAuthenticated}
          />
          <OnlineUsersBar />
        </>
      )}
      
      {/* Mobile Header - Only shows on mobile when authenticated */}
      {authState.isAuthenticated && authState.currentUser && isMobile && (
        <div className="fixed top-0 left-0 w-full bg-white shadow-sm z-50 px-4 py-3 flex items-center justify-between">
          <div className="text-xl font-bold">NIL CRM</div>
          <div className="flex items-center space-x-2">
            <img 
              src={authState.currentUser.profile_image_url || "https://via.placeholder.com/40"} 
              alt={authState.currentUser.display_name} 
              className="w-8 h-8 rounded-full border border-gray-200" 
            />
          </div>
        </div>
      )}
      
      <Routes>
        <Route 
          path="/login" 
          element={
            !authState.isAuthenticated ? (
              <Login onLogin={handleLogin} />
            ) : (
              <Navigate to="/leads" replace />
            )
          } 
        />
        <Route 
          path="/social" 
          element={
            authState.isAuthenticated ? (
              <div style={{ paddingTop: isMobile ? '60px' : '90px' }}>
                <SocialUpdates currentUser={authState.currentUser} />
              </div>
            ) : (
              <Navigate to="/login" replace />
            )
          } 
        />
        <Route 
          path="/dashboard" 
          element={
            authState.isAuthenticated ? <DarkDashboard /> : <Navigate to="/login" replace />
          } 
        />
        <Route 
          path="/leads" 
          element={
            authState.isAuthenticated && authState.currentUser ? (
              <div style={{ paddingTop: isMobile ? '60px' : '90px' }}>
                <ResponsiveLeads currentUser={authState.currentUser} />
              </div>
            ) : (
              <Navigate to="/login" replace />
            )
          } 
        />
        <Route 
          path="/cycles" 
          element={
            authState.isAuthenticated && authState.currentUser?.role === 'administrator' ? (
              <div style={{ paddingTop: isMobile ? '60px' : '90px' }}>
                <CyclesManagement />
              </div>
            ) : (
              <Navigate to="/login" replace />
            )
          } 
        />
        <Route 
          path="/tasks" 
          element={
            authState.isAuthenticated ? (
              <div style={{ paddingTop: isMobile ? '60px' : '90px' }}>
                <TaskList currentUser={authState.currentUser} />
              </div>
            ) : (
              <Navigate to="/login" replace />
            )
          } 
        />
        <Route 
          path="/canned-responses" 
          element={
            authState.isAuthenticated ? (
              <div style={{ paddingTop: isMobile ? '60px' : '90px' }}>
                <CannedResponses />
              </div>
            ) : (
              <Navigate to="/login" replace />
            )
          } 
        />
        <Route 
          path="/reminders" 
          element={
            authState.isAuthenticated ? (
              <div style={{ paddingTop: isMobile ? '60px' : '90px' }}>
                <Reminders currentUser={authState.currentUser} />
              </div>
            ) : (
              <Navigate to="/login" replace />
            )
          } 
        />
        <Route 
          path="/wiki" 
          element={
            authState.isAuthenticated ? (
              <div style={{ paddingTop: isMobile ? '60px' : '90px' }}>
                <Wiki />
              </div>
            ) : (
              <Navigate to="/login" replace />
            )
          } 
        />
        <Route 
          path="/user/:userId" 
          element={
            authState.isAuthenticated ? (
              <div style={{ paddingTop: isMobile ? '60px' : '90px' }}>
                <UserProfile currentUser={authState.currentUser} />
              </div>
            ) : (
              <Navigate to="/login" replace />
            )
          } 
        />
        <Route 
          path="/assignment-rules" 
          element={
            authState.isAuthenticated && authState.currentUser?.role === 'administrator' ? (
              <div style={{ paddingTop: isMobile ? '60px' : '90px' }}>
                <AssignmentRules />
              </div>
            ) : (
              <Navigate to="/login" replace />
            )
          } 
        />
        <Route 
          path="/reports" 
          element={
            <div style={{ paddingTop: isMobile ? '60px' : '90px' }}>
              <Reports />
            </div>
          } 
        />
        <Route path="/" element={<Navigate to="/leads" replace />} />
      </Routes>
      
      {authState.isAuthenticated && authState.currentUser && !isMobile && (
        <>
          <UserNotes 
            visible={notesVisible} 
            onClose={() => setNotesVisible(false)} 
            token={authState.token}
          />
          <GlobalChat />
          <ReminderPopup />
          <button
            onClick={toggleNotes}
            style={{
              position: 'fixed',
              right: '0',
              top: '50%',
              transform: 'translateY(-50%) rotate(-90deg)',
              transformOrigin: 'right bottom',
              zIndex: 1000,
              padding: '10px 20px',
              backgroundColor: 'red',
              color: 'white',
              border: 'none',
              borderRadius: '5px 5px 0 0',
              cursor: 'pointer',
              fontSize: '16px',
              fontWeight: 'bold',
              boxShadow: '-2px 0 5px rgba(0,0,0,0.2)'
            }}
          >
            Notes
          </button>
        </>
      )}
      
      {/* Mobile Bottom Navigation */}
      {authState.isAuthenticated && authState.currentUser && isMobile && (
        <div className="fixed bottom-0 left-0 w-full bg-white border-t flex justify-around items-center py-2 z-40">
          <div 
            className={`flex flex-col items-center ${location.pathname === '/dashboard' ? 'text-blue-500' : 'text-gray-500'}`}
            onClick={() => navigate('/dashboard')}
          >
            <i className="fas fa-home text-lg"></i>
            <span className="text-xs">Home</span>
          </div>
          <div 
            className={`flex flex-col items-center ${location.pathname === '/leads' ? 'text-blue-500' : 'text-gray-500'}`}
            onClick={() => navigate('/leads')}
          >
            <i className="fas fa-users text-lg"></i>
            <span className="text-xs">Leads</span>
          </div>
          <div 
            className={`flex flex-col items-center ${location.pathname === '/tasks' ? 'text-blue-500' : 'text-gray-500'}`}
            onClick={() => navigate('/tasks')}
          >
            <i className="fas fa-tasks text-lg"></i>
            <span className="text-xs">Tasks</span>
          </div>
          <div 
            className={`flex flex-col items-center ${location.pathname === '/social' ? 'text-blue-500' : 'text-gray-500'}`}
            onClick={() => navigate('/social')}
          >
            <i className="fas fa-bell text-lg"></i>
            <span className="text-xs">Updates</span>
          </div>
          <div 
            className="flex flex-col items-center text-gray-500"
            onClick={handleLogout}
          >
            <i className="fas fa-sign-out-alt text-lg"></i>
            <span className="text-xs">Logout</span>
          </div>
        </div>
      )}
      
      <QuotePopup visible={showQuote} onClose={handleCloseQuote} />
    </div>
  );
};

const App = () => {
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    currentUser: null,
    loading: true,
    fullyAuthenticated: false,
    token: null
  });

  const navigate = useNavigate();

  const fetchUserDetails = useCallback(async (token) => {
    try {
      const userDetails = await getCurrentUserDetails();
      
      // Ensure user_id is set properly - this is the key fix
      // If userDetails.user_id is undefined but userDetails.id exists, use that
      if (!userDetails.user_id && userDetails.id) {
        userDetails.user_id = userDetails.id;
      }
      
      setAuthState({
        isAuthenticated: true,
        currentUser: userDetails,
        loading: false,
        fullyAuthenticated: false,
        token: token
      });
  
      setTimeout(() => {
        setAuthState(prev => ({ ...prev, fullyAuthenticated: true }));
      }, 4000);
    } catch (error) {
      console.error('Error fetching user details:', error);
      localStorage.removeItem('jwtToken');
      setAuthState({
        isAuthenticated: false,
        currentUser: null,
        loading: false,
        fullyAuthenticated: false,
        token: null
      });
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    const token = localStorage.getItem('jwtToken');
    if (token) {
      fetchUserDetails(token);
    } else {
      setAuthState(prev => ({ ...prev, loading: false }));
      navigate('/login');
    }
  }, [fetchUserDetails, navigate]);

  const handleLogin = useCallback((token) => {
    localStorage.setItem('jwtToken', token);
    fetchUserDetails(token);
  }, [fetchUserDetails]);

  const handleLogout = useCallback(() => {
    logout();
    setAuthState({
      isAuthenticated: false,
      currentUser: null,
      loading: false,
      fullyAuthenticated: false,
      token: null
    });
    localStorage.removeItem('jwtToken');
    navigate('/login');
  }, [navigate]);

  if (authState.loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <DarkModeProvider>
        <AppContent 
          authState={authState} 
          setAuthState={setAuthState} 
          handleLogin={handleLogin} 
          handleLogout={handleLogout}
        />
      </DarkModeProvider>
    </ThemeProvider>
  );
};

const AppWithRouter = () => (
  <Router>
    <App />
  </Router>
);

export default AppWithRouter;