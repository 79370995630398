import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Card, Statistic, Spin, Alert, Typography, Button, Modal, Select, Divider, Collapse, Table, Tag, Calendar, List } from 'antd';
import { 
    UserOutlined, ReloadOutlined, DollarCircleOutlined, BarChartOutlined, 
    PhoneOutlined, CalendarOutlined, CheckCircleOutlined, CloseCircleOutlined, 
    TrophyOutlined, ArrowUpOutlined, ArrowDownOutlined, TeamOutlined,
    LineChartOutlined, PieChartOutlined, RiseOutlined,
} from '@ant-design/icons';
import { PhoneOutlined as Phone } from '@ant-design/icons';
import { 
    BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, 
    Legend, ResponsiveContainer, Area, AreaChart, PieChart, Pie, Cell 
} from 'recharts';
import { 
    getDashboardData, 
    getCurrentUserDetails, 
    getCoordinatorDetailedStats, 
    getCoordinatorsOverviewStats, 
    updateLead, 
    handleCoordinatorAssign,
    getLead,
    addNote,
    getAllUsers
} from '../apiService';
import { getNextCallLeads } from '../apiService';
import moment from 'moment';
import { DemosGrid, NextCallsGrid } from './LeadCards';
import { message } from 'antd';

const { Title } = Typography;
const { Option } = Select;
const { Panel } = Collapse;

// Dark theme colors
const darkTheme = {
    backgroundColor: '#1f1f1f',
    cardBg: '#262626',
    textPrimary: '#ffffff',
    textSecondary: '#a0a0a0',
    borderColor: '#333333',
    chartColors: ['#1890ff', '#52c41a', '#faad14', '#f5222d', '#722ed1'],
};

const COLORS = darkTheme.chartColors;

const formatLeadForDisplay = (lead) => {
    if (!lead) return lead;
    
    return {
        ...lead,
        booking_date: lead.demo_date ? moment(lead.demo_date).format('D-MMM-YYYY') : 
                     (lead.booking_date ? moment(lead.booking_date).format('D-MMM-YYYY') : null),
        booking_time: lead.demo_time ? moment(lead.demo_time, 'HH:mm:ss').format('hh:mm A') : 
                     (lead.booking_time ? moment(lead.booking_time, 'HH:mm:ss').format('hh:mm A') : null),
        demo_date: lead.demo_date ? moment(lead.demo_date).format('D-MMM-YYYY') : null,
        demo_time: lead.demo_time ? moment(lead.demo_time, 'HH:mm:ss').format('hh:mm A') : null,
        date: lead.date ? moment(lead.date).format('YYYY-MM-DD') : null,
        call_date: lead.call_date ? moment(lead.call_date).format('D-MMM-YYYY hh:mm A') : null,
        next_call_date: lead.next_call_date ? moment(lead.next_call_date).format('D-MMM-YYYY hh:mm A') : null,
        status_history: lead.status_history?.map(history => ({
            ...history,
            changed_at: history.changed_at ? moment(history.changed_at).format('YYYY-MM-DD HH:mm:ss') : null
        })) || [],
        assignment_history: lead.assignment_history?.map(history => ({
            ...history,
            assigned_at: history.assigned_at ? moment(history.assigned_at).format('YYYY-MM-DD HH:mm:ss') : null
        })) || [],
        notes: lead.notes?.map(note => ({
            ...note,
            created_at: note.created_at ? moment(note.created_at).format('YYYY-MM-DD HH:mm:ss') : null
        })) || lead.last_notes?.map(note => ({
            ...note,
            created_at: note.created_at ? moment(note.created_at).format('YYYY-MM-DD HH:mm:ss') : null
        })) || [],
        status: lead.status || 'Unconfirmed'
    };
};

const formatLeadsForDisplay = (leads) => {
    if (!Array.isArray(leads)) return leads;
    return leads.map(formatLeadForDisplay);
};

const Dashboard = () => {
    // State
    const [dashboardData, setDashboardData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedCoordinator, setSelectedCoordinator] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [modalStats, setModalStats] = useState(null);
    const [includeAdminLeads, setIncludeAdminLeads] = useState(true);
    const [currentUser, setCurrentUser] = useState(null);
    const [nextCallLeads, setNextCallLeads] = useState(null);
    const [selectedLead, setSelectedLead] = useState(null);
    const [coordinatorModalVisible, setCoordinatorModalVisible] = useState(false);
    const [users, setUsers] = useState([]);
    const [userError, setUserError] = useState(null);
    const [usersLoaded, setUsersLoaded] = useState(false);
    const [detailedStats, setDetailedStats] = useState(null);
    const [overviewStats, setOverviewStats] = useState(null);
    const [loadingAdditional, setLoadingAdditional] = useState(true);
    const [userStats, setUserStats] = useState(null);

    const cardStyle = {
        backgroundColor: darkTheme.cardBg,
        borderColor: darkTheme.borderColor,
    };

    const statisticStyle = {
        color: darkTheme.textPrimary,
    };

    const tableStyle = {
        backgroundColor: darkTheme.cardBg,
        color: darkTheme.textPrimary,
    };

    const fetchAllData = useCallback(async () => {
        setLoading(true);
        setLoadingAdditional(true);
        setError(null);
        
        try {
            const [userData, dashData] = await Promise.all([
                getCurrentUserDetails(),
                getDashboardData()
            ]);
            setCurrentUser(userData);
            setDashboardData(dashData);
            
            // Set user stats if not an admin
            if (userData && dashData && dashData.coordinator_stats) {
                const stats = dashData.coordinator_stats.find(coord => coord.id === userData.user_id);
                setUserStats(stats);
            }
    
            if (userData.role === 'administrator' || userData.role === 'crm_admin') {
                const overviewData = await getCoordinatorsOverviewStats();
                if (overviewData?.all_todays_demos) {
                    overviewData.all_todays_demos = formatLeadsForDisplay(overviewData.all_todays_demos);
                }
                setOverviewStats(overviewData);
            } else {
                const [detailedData, nextCallData] = await Promise.all([
                    getCoordinatorDetailedStats(userData.user_id),
                    getNextCallLeads()
                ]);
                if (detailedData?.todays_demos) {
                    detailedData.todays_demos = formatLeadsForDisplay(detailedData.todays_demos);
                }
                if (nextCallData?.leads) {
                    nextCallData.leads = formatLeadsForDisplay(nextCallData.leads);
                }
                setDetailedStats(detailedData);
                setNextCallLeads(nextCallData);
            }
        } catch (err) {
            console.error('Error fetching dashboard data:', err);
            setError('Failed to load dashboard data. Please try again later.');
        } finally {
            setLoading(false);
            setLoadingAdditional(false);
        }
    }, []);

    const fetchUsers = useCallback(async () => {
        try {
            setUserError(null);
            const response = await getAllUsers();
            if (response && Array.isArray(response.users)) {
                setUsers(response.users);
                setUsersLoaded(true);
            } else {
                throw new Error('Unexpected user data format');
            }
        } catch (error) {
            console.error('Error fetching users:', error);
            setUserError(error.message || 'Failed to load users');
            setUsersLoaded(true);
        }
    }, []);

    useEffect(() => {
        fetchAllData();
        fetchUsers();
    }, [fetchAllData, fetchUsers]);

    const handleLeadUpdate = async (id, field, value) => {
        try {
            await updateLead(id, { [field]: value });
            message.success('Lead updated successfully');
            fetchAllData();
        } catch (error) {
            console.error('Error updating lead:', error);
            message.error('Failed to update lead');
        }
    };

    const handleCoordinatorAssignment = async (coordinatorId) => {
        try {
            if (!selectedLead) return;
            await updateLead(selectedLead.id, {
                coordinator_id: coordinatorId
            });
            message.success('Coordinator assigned successfully');
            setCoordinatorModalVisible(false);
            fetchAllData();
        } catch (error) {
            console.error('Error assigning coordinator:', error);
            message.error('Failed to assign coordinator');
        }
    };

    const handleAddNote = async (leadId, noteContent) => {
        try {
            await addNote(leadId, noteContent);
            message.success('Note added successfully');
            fetchAllData();
        } catch (error) {
            console.error('Error adding note:', error);
            message.error('Failed to add note');
        }
    };

    const handleCoordinatorClick = (lead) => {
        setSelectedLead(lead);
        setCoordinatorModalVisible(true);
    };

    const renderTodaysDemos = () => {
        if (!detailedStats?.todays_demos?.length) return null;
      
        return (
            <Card 
                title={
                    <div className="flex items-center space-x-2" style={{ color: darkTheme.textPrimary }}>
                        <Calendar className="text-blue-500 h-5 w-5" />
                        <span>Today's Scheduled Demos ({detailedStats.todays_demos.length})</span>
                    </div>
                }
                className="mb-6"
                style={cardStyle}
            >
                <DemosGrid 
                    demos={detailedStats.todays_demos}
                    onLeadUpdate={handleLeadUpdate}
                    onAddNote={handleAddNote}
                    onCoordinatorClick={handleCoordinatorClick}
                    refreshData={fetchAllData}
                    currentUser={currentUser}
                />
            </Card>
        );
    };

    const renderNextCallLeads = () => {
        if (!nextCallLeads?.leads?.length) return null;
      
        return (
            <Card 
                title={
                    <div className="flex items-center space-x-2" style={{ color: darkTheme.textPrimary }}>
                        <Phone className="text-blue-500 h-5 w-5" />
                        <span>Today's Follow-up Calls ({nextCallLeads.total_count})</span>
                    </div>
                }
                className="mb-6"
                style={cardStyle}
            >
                <NextCallsGrid 
                    nextCallLeads={nextCallLeads}
                    onLeadUpdate={handleLeadUpdate}
                    onAddNote={handleAddNote}
                    onCoordinatorClick={handleCoordinatorClick}
                    refreshData={fetchAllData}
                    currentUser={currentUser}
                />
            </Card>
        );
    };

    const renderDetailedStatsCards = () => {
        if (!detailedStats) return null;
    
        const periodTitles = {
            today: 'Today',
            this_week: 'This Week',
            this_month: 'This Month',
            last_month: 'Last Month'
        };
    
        return (
            <Card 
                title={<span style={{ color: darkTheme.textPrimary }}>Detailed Performance Metrics</span>}
                className="mb-6"
                style={cardStyle}
            >
                <Row gutter={[16, 16]}>
                    {Object.entries(periodTitles).map(([period, title]) => (
                        <Col xs={24} md={6} key={period}>
                            <Card 
                                title={<span style={{ color: darkTheme.textPrimary }}>{title}</span>}
                                style={cardStyle}
                            >
                                <Statistic
                                    title={<span style={{ color: darkTheme.textSecondary }}>Leads Approached</span>}
                                    value={detailedStats[period].leads_approached}
                                    prefix={<UserOutlined style={{ color: darkTheme.chartColors[0] }} />}
                                    valueStyle={statisticStyle}
                                />
                                <Divider style={{ borderColor: darkTheme.borderColor }} />
                                <Statistic
                                    title={<span style={{ color: darkTheme.textSecondary }}>Demos Booked</span>}
                                    value={detailedStats[period].demo_booked}
                                    prefix={<CalendarOutlined style={{ color: darkTheme.chartColors[1] }} />}
                                    valueStyle={statisticStyle}
                                />
                                <Divider style={{ borderColor: darkTheme.borderColor }} />
                                <Statistic
                                    title={<span style={{ color: darkTheme.textSecondary }}>Closed Deals</span>}
                                    value={detailedStats[period].closed}
                                    prefix={<CheckCircleOutlined style={{ color: darkTheme.chartColors[2] }} />}
                                    valueStyle={statisticStyle}
                                />
                                <Divider style={{ borderColor: darkTheme.borderColor }} />
                                <Statistic
                                    title={<span style={{ color: darkTheme.textSecondary }}>Admissions</span>}
                                    value={detailedStats[period].admission}
                                    prefix={<TrophyOutlined style={{ color: darkTheme.chartColors[3] }} />}
                                    valueStyle={statisticStyle}
                                />
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Card>
        );
    };

    const renderDetailedStatsChart = () => {
        if (!detailedStats?.daily_stats) return null;

        const dailyData = Object.entries(detailedStats.daily_stats).map(([date, stats]) => ({
            date,
            ...stats
        }));

        return (
            <Card 
                title={<span style={{ color: darkTheme.textPrimary }}>Daily Performance Trends</span>}
                className="mb-6"
                style={cardStyle}
            >
                <ResponsiveContainer width="100%" height={400}>
                    <AreaChart data={dailyData}>
                        <CartesianGrid strokeDasharray="3 3" stroke={darkTheme.borderColor} />
                        <XAxis dataKey="date" stroke={darkTheme.textSecondary} />
                        <YAxis stroke={darkTheme.textSecondary} />
                        <Tooltip 
                            contentStyle={{ 
                                backgroundColor: darkTheme.cardBg, 
                                borderColor: darkTheme.borderColor,
                                color: darkTheme.textPrimary 
                            }}
                        />
                        <Legend wrapperStyle={{ color: darkTheme.textSecondary }} />
                        <Area 
                            type="monotone" 
                            dataKey="leads_assigned" 
                            stackId="1" 
                            stroke={darkTheme.chartColors[0]} 
                            fill={darkTheme.chartColors[0]} 
                            name="Leads Assigned" 
                        />
                        <Area 
                            type="monotone" 
                            dataKey="leads_approached" 
                            stackId="1" 
                            stroke={darkTheme.chartColors[1]} 
                            fill={darkTheme.chartColors[1]} 
                            name="Leads Approached" 
                        />
                        <Area 
                            type="monotone" 
                            dataKey="demo_booked" 
                            stackId="1" 
                            stroke={darkTheme.chartColors[2]} 
                            fill={darkTheme.chartColors[2]} 
                            name="Demos Booked" 
                        />
                        <Area 
                            type="monotone" 
                            dataKey="admissions" 
                            stackId="1" 
                            stroke={darkTheme.chartColors[3]} 
                            fill={darkTheme.chartColors[3]} 
                            name="Admissions" 
                        />
                    </AreaChart>
                </ResponsiveContainer>
            </Card>
        );
    };

    const renderRevenueComparison = () => {
        const { current_month, previous_month } = dashboardData;
        const data = [
            {
                name: 'Previous Month',
                'Generated Revenue': parseFloat(previous_month.generated_revenue),
                'Expected Revenue': parseFloat(previous_month.expected_revenue),
            },
            {
                name: 'Current Month',
                'Generated Revenue': parseFloat(current_month.generated_revenue),
                'Expected Revenue': parseFloat(current_month.expected_revenue),
            },
        ];

        return (
            <Card 
                title={<span style={{ color: darkTheme.textPrimary }}>Revenue Comparison</span>}
                style={cardStyle}
                className="h-full"
            >
                <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={data}>
                        <CartesianGrid strokeDasharray="3 3" stroke={darkTheme.borderColor} />
                        <XAxis dataKey="name" stroke={darkTheme.textSecondary} />
                        <YAxis stroke={darkTheme.textSecondary} />
                        <Tooltip 
                            contentStyle={{ 
                                backgroundColor: darkTheme.cardBg, 
                                borderColor: darkTheme.borderColor,
                                color: darkTheme.textPrimary 
                            }}
                        />
                        <Legend wrapperStyle={{ color: darkTheme.textSecondary }} />
                        <Bar dataKey="Generated Revenue" fill={darkTheme.chartColors[0]} />
                        <Bar dataKey="Expected Revenue" fill={darkTheme.chartColors[1]} />
                    </BarChart>
                </ResponsiveContainer>
            </Card>
        );
    };

    const renderLeadComparisonGraph = () => {
        if (!dashboardData || !dashboardData.leads_admissions_comparison) {
            return (
                <Alert 
                    message="Lead comparison data is not available" 
                    type="warning"
                    style={{ backgroundColor: darkTheme.cardBg, borderColor: darkTheme.borderColor }}
                />
            );
        }

        const { current_month, previous_month } = dashboardData.leads_admissions_comparison;
        const data = [
            {
                name: 'Previous Month',
                Leads: previous_month.leads,
                Admissions: previous_month.admissions,
            },
            {
                name: 'Current Month',
                Leads: current_month.leads,
                Admissions: current_month.admissions,
            },
        ];

        return (
            <Card 
                title={<span style={{ color: darkTheme.textPrimary }}>Lead and Admission Comparison</span>}
                style={cardStyle}
                className="h-full"
            >
                <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={data}>
                        <CartesianGrid strokeDasharray="3 3" stroke={darkTheme.borderColor} />
                        <XAxis dataKey="name" stroke={darkTheme.textSecondary} />
                        <YAxis stroke={darkTheme.textSecondary} />
                        <Tooltip 
                            contentStyle={{ 
                                backgroundColor: darkTheme.cardBg, 
                                borderColor: darkTheme.borderColor,
                                color: darkTheme.textPrimary 
                            }}
                        />
                        <Legend wrapperStyle={{ color: darkTheme.textSecondary }} />
                        <Bar dataKey="Leads" fill={darkTheme.chartColors[0]} />
                        <Bar dataKey="Admissions" fill={darkTheme.chartColors[1]} />
                    </BarChart>
                </ResponsiveContainer>
            </Card>
        );
    };

    const renderAllTodaysDemos = () => {
        if (!overviewStats?.all_todays_demos?.length) return null;
      
        return (
            <Card 
                title={
                    <div className="flex justify-between items-center" style={{ color: darkTheme.textPrimary }}>
                        <div className="flex items-center space-x-2">
                            <Calendar className="text-blue-500 h-5 w-5" />
                            <span>Today's Scheduled Demos ({overviewStats.all_todays_demos.length})</span>
                        </div>
                        <Statistic 
                            value={overviewStats.total_demos_today} 
                            prefix={<Calendar className="h-4 w-4" />} 
                            valueStyle={{ color: '#1890ff' }}
                            suffix="Total"
                        />
                    </div>
                }
                className="mb-6"
                style={cardStyle}
            >
                <DemosGrid 
                    demos={overviewStats.all_todays_demos} 
                    onLeadUpdate={handleLeadUpdate}
                    onAddNote={handleAddNote}
                    onCoordinatorClick={handleCoordinatorClick}
                    refreshData={fetchAllData}
                    currentUser={currentUser}
                />
            </Card>
        );
    };

    const renderCoordinatorStats = (stats) => (
        <Card style={cardStyle}>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={6}>
                    <Statistic 
                        title={<span style={{ color: darkTheme.textSecondary }}>Total Admissions</span>}
                        value={stats.current_admissions} 
                        prefix={<TrophyOutlined style={{ color: darkTheme.chartColors[0] }} />}
                        valueStyle={{ ...statisticStyle, color: darkTheme.chartColors[0], fontWeight: 'bold' }}
                    />
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Statistic 
                        title={<span style={{ color: darkTheme.textSecondary }}>Total Follow Ups</span>}
                        value={stats.total_follow_ups} 
                        prefix={<PhoneOutlined style={{ color: darkTheme.chartColors[1] }} />}
                        valueStyle={statisticStyle}
                    />
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Statistic 
                        title={<span style={{ color: darkTheme.textSecondary }}>Demo Booked</span>}
                        value={stats.demo_booked} 
                        prefix={<CalendarOutlined style={{ color: darkTheme.chartColors[2] }} />}
                        valueStyle={statisticStyle}
                    />
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Statistic 
                        title={<span style={{ color: darkTheme.textSecondary }}>Demo Done</span>}
                        value={stats.demo_done} 
                        prefix={<CheckCircleOutlined style={{ color: darkTheme.chartColors[3] }} />}
                        valueStyle={statisticStyle}
                    />
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Statistic 
                        title={<span style={{ color: darkTheme.textSecondary }}>Demo Missed</span>}
                        value={stats.demo_missed} 
                        prefix={<CloseCircleOutlined style={{ color: darkTheme.chartColors[4] }} />}
                        valueStyle={statisticStyle}
                    />
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Statistic 
                        title={<span style={{ color: darkTheme.textSecondary }}>Closed</span>}
                        value={stats.closed} 
                        prefix={<CheckCircleOutlined style={{ color: darkTheme.chartColors[0] }} />}
                        valueStyle={statisticStyle}
                    />
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Statistic 
                        title={<span style={{ color: darkTheme.textSecondary }}>Total NP</span>}
                        value={stats.total_np} 
                        prefix={<UserOutlined style={{ color: darkTheme.chartColors[1] }} />}
                        valueStyle={statisticStyle}
                    />
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Statistic 
                        title={<span style={{ color: darkTheme.textSecondary }}>Unconfirmed</span>}
                        value={stats.unconfirmed}
                        prefix={<TeamOutlined style={{ color: darkTheme.chartColors[2] }} />}
                        valueStyle={statisticStyle}
                    />
                </Col>
            </Row>
            <Divider style={{ borderColor: darkTheme.borderColor }} />
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <Statistic
                        title={<span style={{ color: darkTheme.textSecondary }}>Expected Revenue</span>}
                        value={stats.expected_revenue}
                        precision={2}
                        prefix={<DollarCircleOutlined style={{ color: darkTheme.chartColors[3] }} />}
                        suffix="₹"
                        valueStyle={statisticStyle}
                    />
                </Col>
                <Col span={12}>
                    <Statistic
                        title={<span style={{ color: darkTheme.textSecondary }}>Generated Revenue</span>}
                        value={stats.revenue_generated}
                        precision={2}
                        prefix={<DollarCircleOutlined style={{ color: darkTheme.chartColors[4] }} />}
                        suffix="₹"
                        valueStyle={statisticStyle}
                    />
                </Col>
            </Row>
        </Card>
    );

    const renderOverviewStatsCards = () => {
        if (!overviewStats) return null;
    
        return (
            <Card 
                title={<span style={{ color: darkTheme.textPrimary }}>Team Performance Overview</span>}
                className="mb-6"
                style={cardStyle}
            >
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={6}>
                        <Statistic
                            title={<span style={{ color: darkTheme.textSecondary }}>Total Demos Today</span>}
                            value={overviewStats.total_demos_today}
                            prefix={<CalendarOutlined style={{ color: darkTheme.chartColors[0] }} />}
                            valueStyle={{ ...statisticStyle, color: darkTheme.chartColors[0] }}
                        />
                    </Col>
                    <Col xs={24}>
                        <Card 
                            title={<span style={{ color: darkTheme.textPrimary }}>Lead Statistics</span>}
                            bordered={false}
                            style={cardStyle}
                        >
                            <Row gutter={[16, 16]}>
                                <Col xs={24} md={12}>
                                    <Card 
                                        title={<span style={{ color: darkTheme.textPrimary }}>Today's Leads</span>}
                                        type="inner"
                                        style={cardStyle}
                                    >
                                        <Row gutter={[8, 8]}>
                                            <Col span={8}>
                                                <Statistic
                                                    title={<span style={{ color: darkTheme.textSecondary }}>Total</span>}
                                                    value={overviewStats.leads_today.total}
                                                    prefix={<UserOutlined style={{ color: darkTheme.chartColors[0] }} />}
                                                    valueStyle={{ ...statisticStyle, color: darkTheme.chartColors[0] }}
                                                />
                                            </Col>
                                            <Col span={8}>
                                                <Statistic
                                                    title={<span style={{ color: darkTheme.textSecondary }}>Assigned</span>}
                                                    value={overviewStats.leads_today.assigned}
                                                    prefix={<CheckCircleOutlined style={{ color: darkTheme.chartColors[1] }} />}
                                                    valueStyle={{ ...statisticStyle, color: darkTheme.chartColors[1] }}
                                                />
                                            </Col>
                                            <Col span={8}>
                                                <Statistic
                                                    title={<span style={{ color: darkTheme.textSecondary }}>Unassigned</span>}
                                                    value={overviewStats.leads_today.unassigned}
                                                    prefix={<CloseCircleOutlined style={{ color: darkTheme.chartColors[2] }} />}
                                                    valueStyle={{ ...statisticStyle, color: darkTheme.chartColors[2] }}
                                                />
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                
                                <Col xs={24} md={12}>
                                    <Card 
                                        title={<span style={{ color: darkTheme.textPrimary }}>Yesterday's Leads</span>}
                                        type="inner"
                                        style={cardStyle}
                                    >
                                        <Row gutter={[8, 8]}>
                                            <Col span={8}>
                                                <Statistic
                                                    title={<span style={{ color: darkTheme.textSecondary }}>Total</span>}
                                                    value={overviewStats.leads_yesterday.total}
                                                    prefix={<UserOutlined style={{ color: darkTheme.chartColors[0] }} />}
                                                    valueStyle={{ ...statisticStyle, color: darkTheme.chartColors[0] }}
                                                />
                                            </Col>
                                            <Col span={8}>
                                                <Statistic
                                                    title={<span style={{ color: darkTheme.textSecondary }}>Assigned</span>}
                                                    value={overviewStats.leads_yesterday.assigned}
                                                    prefix={<CheckCircleOutlined style={{ color: darkTheme.chartColors[1] }} />}
                                                    valueStyle={{ ...statisticStyle, color: darkTheme.chartColors[1] }}
                                                />
                                            </Col>
                                            <Col span={8}>
                                                <Statistic
                                                    title={<span style={{ color: darkTheme.textSecondary }}>Unassigned</span>}
                                                    value={overviewStats.leads_yesterday.unassigned}
                                                    prefix={<CloseCircleOutlined style={{ color: darkTheme.chartColors[2] }} />}
                                                    valueStyle={{ ...statisticStyle, color: darkTheme.chartColors[2] }}
                                                />
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Card>
        );
    };

    const renderMonthlyTotals = () => {
        if (!overviewStats?.monthly_totals) return null;

        const data = [
            { name: 'Leads Assigned', value: overviewStats.monthly_totals.leads_assigned },
            { name: 'Leads Approached', value: overviewStats.monthly_totals.leads_approached },
            { name: 'Demos Booked', value: overviewStats.monthly_totals.demo_booked },
            { name: 'Admissions', value: overviewStats.monthly_totals.admissions },
        ];

        return (
            <Card 
                title={<span style={{ color: darkTheme.textPrimary }}>Monthly Performance Distribution</span>}
                className="mb-6"
                style={cardStyle}
            >
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={12}>
                        <ResponsiveContainer width="100%" height={300}>
                            <PieChart>
                                <Pie
                                    data={data}
                                    dataKey="value"
                                    nameKey="name"
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={100}
                                    fill="#8884d8"
                                    label
                                >
                                    {data.map((entry, index) => (
                                        <Cell 
                                            key={`cell-${index}`} 
                                            fill={darkTheme.chartColors[index % darkTheme.chartColors.length]} 
                                        />
                                    ))}
                                </Pie>
                                <Tooltip 
                                    contentStyle={{ 
                                        backgroundColor: darkTheme.cardBg, 
                                        borderColor: darkTheme.borderColor,
                                        color: darkTheme.textPrimary 
                                    }}
                                />
                                <Legend 
                                    wrapperStyle={{ color: darkTheme.textSecondary }}
                                    formatter={(value) => <span style={{ color: darkTheme.textSecondary }}>{value}</span>}
                                />
                            </PieChart>
                        </ResponsiveContainer>
                    </Col>
                    <Col xs={24} md={12}>
                        <div className="stats-summary">
                            {data.map((item, index) => (
                                <Statistic
                                    key={item.name}
                                    title={<span style={{ color: darkTheme.textSecondary }}>{item.name}</span>}
                                    value={item.value}
                                    valueStyle={{ color: darkTheme.chartColors[index % darkTheme.chartColors.length] }}
                                />
                            ))}
                        </div>
                    </Col>
                </Row>
            </Card>
        );
    };

    const renderTeamPerformanceChart = () => {
        if (!overviewStats?.rankings?.this_month) return null;

        const data = overviewStats.rankings.this_month.map(coord => ({
            name: coord.name,
            'Leads Approached': coord.leads_approached,
            'Admissions': coord.admissions,
            'Demos Booked': coord.demo_booked,
            'Conversion Rate': ((coord.admissions / coord.leads_approached) * 100).toFixed(2)
        }));

        return (
            <Card 
                title={<span style={{ color: darkTheme.textPrimary }}>Team Monthly Performance Rankings</span>}
                className="mb-6"
                style={cardStyle}
            >
                <ResponsiveContainer width="100%" height={400}>
                    <BarChart data={data}>
                        <CartesianGrid strokeDasharray="3 3" stroke={darkTheme.borderColor} />
                        <XAxis dataKey="name" stroke={darkTheme.textSecondary} />
                        <YAxis yAxisId="left" stroke={darkTheme.textSecondary} />
                        <YAxis yAxisId="right" orientation="right" stroke={darkTheme.textSecondary} />
                        <Tooltip 
                            contentStyle={{ 
                                backgroundColor: darkTheme.cardBg, 
                                borderColor: darkTheme.borderColor,
                                color: darkTheme.textPrimary 
                            }}
                        />
                        <Legend wrapperStyle={{ color: darkTheme.textSecondary }} />
                        <Bar yAxisId="left" dataKey="Leads Approached" fill={darkTheme.chartColors[0]} />
                        <Bar yAxisId="left" dataKey="Admissions" fill={darkTheme.chartColors[1]} />
                        <Bar yAxisId="left" dataKey="Demos Booked" fill={darkTheme.chartColors[2]} />
                        <Line 
                            yAxisId="right" 
                            type="monotone" 
                            dataKey="Conversion Rate" 
                            stroke={darkTheme.chartColors[3]} 
                        />
                    </BarChart>
                </ResponsiveContainer>
            </Card>
        );
    };

    const renderCoordinatorDashboard = () => {
        if (!userStats) return null;

        return (
            <div className="flex">
                {/* Main Content Area */}
                <div className="flex-1 pr-4 -mt-5">
                    <div className="mb-6">
                        {renderTodaysDemos()}
                    </div>
                    <div className="mb-6">
                        {renderNextCallLeads()}
                    </div>
                    <div className="mb-6">
                        {renderDetailedStatsCards()}
                    </div>
                    <div className="mb-6">
                        {renderDetailedStatsChart()}
                    </div>
                </div>

                {/* Right Sidebar */}
                <div className="w-96 flex-shrink-0">
                    <div className="fixed w-96 h-screen overflow-y-auto pb-20 pr-4 " style={{ backgroundColor: darkTheme.cardBg }}>
                        <Card 
                            title={
                                <span style={{ color: darkTheme.textPrimary }}>
                                    {`Your Statistics (${currentUser.display_name})`}
                                </span>
                            }
                            style={cardStyle}
                            className="mb-4"
                        >
                            <div className="space-y-4">
                                <div>
                                    <Statistic
                                        title={<span style={{ color: darkTheme.textSecondary }}>Total Admissions</span>}
                                        value={userStats.current_admissions}
                                        prefix={<TrophyOutlined style={{ color: darkTheme.chartColors[0] }} />}
                                        valueStyle={{ color: darkTheme.chartColors[0], fontWeight: 'bold' }}
                                    />
                                </div>
                                <div>
                                    <Statistic
                                        title={<span style={{ color: darkTheme.textSecondary }}>Total Follow Ups</span>}
                                        value={userStats.total_follow_ups}
                                        prefix={<PhoneOutlined style={{ color: darkTheme.chartColors[1] }} />}
                                        valueStyle={statisticStyle}
                                    />
                                </div>
                                <Divider style={{ borderColor: darkTheme.borderColor }} />
                                <div>
                                    <Statistic
                                        title={<span style={{ color: darkTheme.textSecondary }}>Demo Booked</span>}
                                        value={userStats.demo_booked}
                                        prefix={<CalendarOutlined style={{ color: darkTheme.chartColors[2] }} />}
                                        valueStyle={statisticStyle}
                                    />
                                </div>
                                <div>
                                    <Statistic
                                        title={<span style={{ color: darkTheme.textSecondary }}>Demo Done</span>}
                                        value={userStats.demo_done}
                                        prefix={<CheckCircleOutlined style={{ color: darkTheme.chartColors[3] }} />}
                                        valueStyle={statisticStyle}
                                    />
                                </div>
                                <div>
                                    <Statistic
                                        title={<span style={{ color: darkTheme.textSecondary }}>Demo Missed</span>}
                                        value={userStats.demo_missed}
                                        prefix={<CloseCircleOutlined style={{ color: darkTheme.chartColors[4] }} />}
                                        valueStyle={statisticStyle}
                                    />
                                </div>
                                <Divider style={{ borderColor: darkTheme.borderColor }} />
                                <div>
                                    <Statistic
                                        title={<span style={{ color: darkTheme.textSecondary }}>Expected Revenue</span>}
                                        value={userStats.expected_revenue}
                                        precision={2}
                                        prefix={<DollarCircleOutlined style={{ color: darkTheme.chartColors[3] }} />}
                                        suffix="₹"
                                        valueStyle={statisticStyle}
                                    />
                                </div>
                                <div>
                                    <Statistic
                                        title={<span style={{ color: darkTheme.textSecondary }}>Generated Revenue</span>}
                                        value={userStats.revenue_generated}
                                        precision={2}
                                        prefix={<DollarCircleOutlined style={{ color: darkTheme.chartColors[4] }} />}
                                        suffix="₹"
                                        valueStyle={statisticStyle}
                                    />
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        );
    };

    const renderAdminDashboard = () => (
        <div className="w-full">
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={8}>
                    <Card style={cardStyle}>
                        <Statistic
                            title={<span style={{ color: darkTheme.textSecondary }}>Total Leads</span>}
                            value={dashboardData.lead_stats.total_leads}
                            prefix={<UserOutlined style={{ color: darkTheme.chartColors[0] }} />}
                            valueStyle={{ ...statisticStyle, color: darkTheme.chartColors[0] }}
                        />
                    </Card>
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <Card style={cardStyle}>
                        <Statistic
                            title={<span style={{ color: darkTheme.textSecondary }}>Total Admissions</span>}
                            value={dashboardData.lead_stats.total_admissions}
                            prefix={<TrophyOutlined style={{ color: darkTheme.chartColors[1] }} />}
                            valueStyle={{ ...statisticStyle, color: darkTheme.chartColors[1], fontWeight: 'bold' }}
                        />
                    </Card>
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <Card style={cardStyle}>
                        <Statistic
                            title={<span style={{ color: darkTheme.textSecondary }}>Generated Revenue (Current Month)</span>}
                            value={dashboardData.current_month.generated_revenue}
                            precision={2}
                            prefix={<DollarCircleOutlined style={{ color: darkTheme.chartColors[2] }} />}
                            suffix="₹"
                            valueStyle={{ ...statisticStyle, color: darkTheme.chartColors[2] }}
                        />
                    </Card>
                </Col>
            </Row>

            {renderOverviewStatsCards()}
            {renderAllTodaysDemos()}
            {renderTeamPerformanceChart()}
            {renderMonthlyTotals()}

            <Row gutter={[16, 16]} className="mt-6">
                <Col xs={24} md={12}>
                    {renderLeadComparisonGraph()}
                </Col>
                <Col xs={24} md={12}>
                    {renderRevenueComparison()}
                </Col>
            </Row>

            <Row gutter={[16, 16]} className="mt-6">
                <Col xs={24}>
                    <Card 
                        title={<span style={{ color: darkTheme.textPrimary }}>Coordinator Statistics</span>}
                        style={cardStyle}
                        extra={
                            <Select
                                defaultValue={includeAdminLeads}
                                style={{ width: 200 }}
                                onChange={(value) => setIncludeAdminLeads(value)}
                                dropdownStyle={{ backgroundColor: darkTheme.cardBg }}
                            >
                                <Option value={true}>Include Admin Leads</Option>
                                <Option value={false}>Exclude Admin Leads</Option>
                            </Select>
                        }
                    >
                        <Collapse 
                            style={{ backgroundColor: darkTheme.cardBg }}
                            bordered={false}
                        >
                            {(includeAdminLeads ? dashboardData.coordinator_stats : 
                              dashboardData.coordinator_stats.filter(stat => stat.id !== 'admin'))
                              .map((coordinator) => (
                                <Panel 
                                    header={
                                        <span style={{ color: darkTheme.textPrimary }}>
                                            {coordinator.name}
                                        </span>
                                    }
                                    key={coordinator.id}
                                    style={{ backgroundColor: darkTheme.cardBg, borderColor: darkTheme.borderColor }}
                                >
                                    {renderCoordinatorStats(coordinator)}
                                    <Button 
                                        onClick={() => handleCoordinatorClick(coordinator)} 
                                        style={{ marginTop: '16px' }}
                                        type="primary"
                                    >
                                        View Detailed Stats
                                    </Button>
                                </Panel>
                            ))}
                        </Collapse>
                    </Card>
                </Col>
            </Row>
        </div>
    );

    if (loading || loadingAdditional) {
        return (
            <div style={{ 
                backgroundColor: darkTheme.backgroundColor,
                minHeight: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Spin size="large" />
            </div>
        );
    }

    if (error) {
        return (
            <div style={{ backgroundColor: darkTheme.backgroundColor, minHeight: '100vh', padding: '20px' }}>
                <Alert 
                    message="Error" 
                    description={error} 
                    type="error" 
                    showIcon 
                    style={{ backgroundColor: darkTheme.cardBg, borderColor: darkTheme.borderColor }}
                />
            </div>
        );
    }

    if (!dashboardData || !currentUser) return null;

    const isAdmin = currentUser.role === 'administrator' || currentUser.role === 'crm_admin';

    return (
        <div className="w-full h-full" style={{ backgroundColor: darkTheme.backgroundColor, minHeight: '100vh' }}>
            <div className="p-8">
                <div className="flex justify-between items-center mb-6">
                    <Title level={2} style={{ color: darkTheme.textPrimary }}>Dashboard</Title>
                    <Button 
                        icon={<ReloadOutlined />} 
                        onClick={fetchAllData}
                        className="bg-blue-500 hover:bg-blue-600 text-white"
                    >
                        Refresh
                    </Button>
                </div>

                {isAdmin ? renderAdminDashboard() : renderCoordinatorDashboard()}

                <Modal
                    title={
                        <span style={{ color: darkTheme.textPrimary }}>
                            {`${selectedCoordinator?.name}'s Detailed Statistics`}
                        </span>
                    }
                    visible={modalVisible}
                    onCancel={() => setModalVisible(false)}
                    footer={null}
                    width={800}
                    style={{ backgroundColor: darkTheme.backgroundColor }}
                    bodyStyle={{ backgroundColor: darkTheme.cardBg }}
                >
                    {modalLoading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
                            <Spin size="large" />
                        </div>
                    ) : modalStats ? (
                        <>
                            {renderCoordinatorStats(modalStats)}
                            <Divider style={{ borderColor: darkTheme.borderColor }} />
                            {renderDetailedStatsChart()}
                        </>
                    ) : null}
                </Modal>

                <Modal
                    title={<span style={{ color: darkTheme.textPrimary }}>Assign Coordinator</span>}
                    visible={coordinatorModalVisible}
                    onCancel={() => setCoordinatorModalVisible(false)}
                    footer={null}
                    style={{ backgroundColor: darkTheme.backgroundColor }}
                    bodyStyle={{ backgroundColor: darkTheme.cardBg }}
                >
                    <List
                        dataSource={users}
                        renderItem={user => (
                            <List.Item
                                key={user.id}
                                onClick={() => handleCoordinatorAssignment(user.id)}
                                style={{ 
                                    cursor: 'pointer',
                                    color: darkTheme.textPrimary,
                                    borderColor: darkTheme.borderColor,
                                    padding: '8px',
                                    '&:hover': {
                                        backgroundColor: darkTheme.borderColor
                                    }
                                }}
                            >
                                {user.display_name || user.username}
                            </List.Item>
                        )}
                        style={{ backgroundColor: darkTheme.cardBg }}
                    />
                </Modal>
            </div>
        </div>
    );
};

export default Dashboard;